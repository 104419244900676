import {useIntl} from 'react-intl';
import ClientsUI from './ClientsUI';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {useEffect} from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {fetchError, showMessage} from 'redux/actions';

const ClientsLG = () => {
  const {messages} = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearchQuery] = useState('');

  const onPageChange = (event, value) => {
    setPage(value);
  };

  const onSearchCustomer = (e) => {
    setSearchQuery(e);
    setPage(0);
  };

  const OpenAddCustomer = () => {
    navigate('/clients/add-client');
  };

  const getCustomers = (pageSize, pageNumber, search) => {
    const url = search
      ? `customer/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`
      : `customer?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setCustomers(e.data.data.data);
        setCustomersCount(e.data.data.dataCount);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };
  const deleteCustomer = (id) => {
    jwtAxios
      .delete(`customer/${id}`)
      .then(() => {
        dispatch(showMessage('sccusfully data deleted id= ' + id));
        getCustomers(10, page, search);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  useEffect(() => {
    if (search) {
      const getData = setTimeout(() => {
        getCustomers(10, page, search);
      }, 2000);
      return () => clearTimeout(getData);
    }
    getCustomers(10, page, search);
  }, [page, search]);

  return (
    <ClientsUI
      page={page}
      onPageChange={onPageChange}
      customers={customers}
      customerCount={customersCount}
      messages={messages}
      onSearchCustomer={onSearchCustomer}
      OpenAddCustomer={OpenAddCustomer}
      deleteCustomer={deleteCustomer}
    />
  );
};

export default ClientsLG;
