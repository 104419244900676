import {Grid, TextField} from '@mui/material';
import PropTypes from 'prop-types';

const TrackboxForm = ({formik}) => {
  return (
    <Grid itme container spacing={3}>
      <Grid item xs={12} lg={6}>
        <TextField
          id='ai'
          name='ai'
          label='AI'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              ai: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.ai}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='ci'
          name='ci'
          label='CI'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              ci: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.ci}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='gi'
          name='gi'
          label='GI'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              gi: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.gi}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='user_ip'
          name='user_ip'
          label='User Ip'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              user_ip: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.user_ip}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='trackbox_username'
          name='trackbox_username'
          label='trackbox_username'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              trackbox_username: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.trackbox_username}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='trackbox_password'
          name='trackbox_password'
          label='trackbox_password'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              trackbox_password: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.trackbox_password}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='api_key'
          name='api_key'
          label='api_key'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              api_key: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.api_key}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='sub'
          name='sub'
          label='sub'
          variant='outlined'
          onChange={(e) => {
            formik.setFieldValue('additional_data_integration', {
              ...formik.values.additional_data_integration,
              sub: e.target.value,
            });
          }}
          value={formik.values.additional_data_integration.sub}
        />
      </Grid>
    </Grid>
  );
};
TrackboxForm.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default TrackboxForm;
