import React from 'react';
import CustomerTable from './ClientsTable';
import AppsContainer from '../../../@crema/core/AppsContainer';
import {Button, Hidden} from '@mui/material';
import AppsHeader from '../../../@crema/core/AppsContainer/AppsHeader';
import AppsContent from '../../../@crema/core/AppsContainer/AppsContent';
import AppsPagination from '../../../@crema/core/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '../../../@crema/core/AppInfoView';
import AppSearchBar from '../../../@crema/core/AppSearchBar';
import PropTypes from 'prop-types';

const ClientsUI = ({
  page,
  onPageChange,
  customers,
  customerCount,
  messages,
  onSearchCustomer,
  OpenAddCustomer,
  deleteCustomer,
}) => {
  return (
    <>
      <AppsContainer title={messages['sidebar.ecommerce.customers']} fullView>
        <AppsHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
            }}
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={(event) => onSearchCustomer(event.target.value)}
              placeholder={messages['common.searchHere']}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={OpenAddCustomer}
              >
                Add Customer
              </Button>

              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={10}
                  count={customerCount}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <CustomerTable
            customers={customers}
            deleteCustomer={deleteCustomer}
          />
        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={10}
            count={customerCount}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>

      <AppInfoView />
    </>
  );
};
ClientsUI.propTypes = {
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  customerCount: PropTypes.number.isRequired,
  messages: PropTypes.object.isRequired,
  onSearchCustomer: PropTypes.func.isRequired,
  OpenAddCustomer: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
};

export default ClientsUI;
