import { useEffect } from 'react';
import ClientUI from './ClientUI';
import { useState } from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';
import { useParams } from 'react-router-dom';
import { AppInfoView } from '@crema';
import { useFormik } from 'formik';
import { isHttpValid } from 'utils';

const ClientGL = () => {
  const dispatcher = useDispatch();
  const { id } = useParams();
  const [customer, setCustomer] = useState();
  const [integrationType, setIntegrationType] = useState('');
  const [isGettingCustomer, setIsGettingCustomer] = useState(false);
  const [isGettingCustomerData, setIsGettingCustomerData] = useState(false);
  const [isUpdateing, setIsUpdateing] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [urlCode, setUrl] = useState('');
  const [httpMethod, setHttpMethod] = useState('');
  const [pageSize] = useState(10);
  const [openAttachModal, setAttachModal] = useState(false);

  const formik = useFormik({
    onSubmit: (values) => {
      if (
        integrationType == 'trackbox' && !isHttpValid(values.integration_url)
      ) {
        dispatcher(fetchError('url not valid'));
        return;
      }
      console.log(integrationType == 'customUrl');
      let customUrlObject = {};
      if (integrationType == 'customUrl') {
        customUrlObject = {
          url: urlCode,
          method: httpMethod,
        };
      }
      let requestBody = integrationType == 'customUrl' ?
        {
          ...values,
          additional_data_integration: { ...customUrlObject },
          integration_type: integrationType,
        } : {
          ...values,
          integration_type: integrationType,

        };

      setIsUpdateing(true);
      jwtAxios
        .put(`customer/${id}`, requestBody)
        .then(() => {
          setOpenConfirmModal(true);
        })
        .catch((e) => {
          dispatcher(fetchError(e.message));
        })
        .finally(() => {
          setIsUpdateing(false);
        });
    },
  });

  const getCustomerData = async () => {
    setIsGettingCustomerData(true);
    await jwtAxios
      .get(`data/customer/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`)
      .then((res) => {
        setCustomerData(res.data.data.customer_data);
        setDataCount(res.data.data.data_count);
      })
      .catch((e) => {
        dispatcher(fetchError(e.message));
      })
      .finally(() => {
        setIsGettingCustomerData(false);
      });
  };

  const getCustomer = async () => {
    setIsGettingCustomer(true);
    await jwtAxios
      .get(`customer/${id}`)
      .then((res) => {
        setCustomer(res.data.data.customer);
        formik.setValues({
          ...res.data.data.customer,
        });
        setIntegrationType(res.data.data.customer.integration_type);
        if (res.data.data.customer.integration_type == 'customUrl') {
          setUrl(res.data.data.customer.additional_data_integration.url);
          setHttpMethod(
            res.data.data.customer.additional_data_integration.method,
          );
        }
      })
      .catch((e) => {
        dispatcher(fetchError(e.message));
      })
      .finally(() => {
        setIsGettingCustomer(false);
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    getCustomerData();
  }, [pageNumber]);

  const handleSwitchIntegrationType = (e) => {
    setIntegrationType(e);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(true);
  };
  const onPageChange = (event, value) => {
    setPageNumber(value);
  };

  return (
    <>
      <AppInfoView />
      {console.log(customer)}
      {customer && !isGettingCustomer && (
        <ClientUI
          formik={formik}
          integrationType={integrationType}
          handleSwitchIntegrationType={handleSwitchIntegrationType}
          openConfirmModal={openConfirmModal}
          handleCloseModal={handleCloseModal}
          isUpdateing={isUpdateing}
          customerData={customerData}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setUrl={setUrl}
          url={urlCode}
          httpMethod={httpMethod}
          setHttpMethod={setHttpMethod}
          dataCount={dataCount}
          onPageChange={onPageChange}
          setAttachModal={setAttachModal}
          openAttachModal={openAttachModal}
          customerId={customer?.id}
          isGettingCustomerData={isGettingCustomerData}
        />
      )}
    </>
  );
};

export default ClientGL;
