import React from 'react';
import {TableCell} from '@mui/material';
import TableHeader from '@crema/core/AppTable/TableHeader';
import PropTypes from 'prop-types';

const TableHeading = ({headings}) => {
  return (
    <TableHeader>
      {headings.map((heading) => {
        return (
          <TableCell key={heading} align='left'>
            {heading}
          </TableCell>
        );
      })}
    </TableHeader>
  );
};
TableHeading.propTypes = {
  headings: PropTypes.array.isRequired,
};
export default TableHeading;
