import ExportToExcelButton from '@crema/core/AppExportExeclFile';
import {Box, Typography} from '@mui/material';
import PropsType from 'prop-types';
import Matrix from '../Matrix';
import {green} from '@mui/material/colors';
const MatrixForBDAPI = ({
  allStatusCountDB,
  doneStatusCountDB,
  errorStatusCountDB,
  errorDataDB,
  allStatusCountAPI,
  doneStatusCountAPI,
  errorStatusCountAPI,
  errorDataAPI,
  messages,
  fileName,
  isClicked,
}) => {
  return (
    <>
      {isClicked ? (
        <Matrix
          title={messages['addData.DB']}
          doneStatusCount={doneStatusCountDB}
          errorStatusCount={errorStatusCountDB}
          allStatusCount={allStatusCountDB}
          messages={messages}
        />
      ) : (
        ''
      )}
      {errorStatusCountDB > 0 ? (
        <ExportToExcelButton fileName={fileName} json_data={errorDataDB} />
      ) : isClicked ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ml: 'auto',
            mt: '1rem',
            color: green[500],
          }}
        >
          <Typography>Data uploaded successfully!</Typography>
        </Box>
      ) : null}

      {isClicked ? (
        <Matrix
          title={messages['addData.API']}
          doneStatusCount={doneStatusCountAPI}
          errorStatusCount={errorStatusCountAPI}
          allStatusCount={allStatusCountAPI}
          messages={messages}
        />
      ) : (
        ''
      )}
      {errorStatusCountAPI > 0 ? (
        <ExportToExcelButton fileName={fileName} json_data={errorDataAPI} />
      ) : isClicked ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ml: 'auto',
            mt: '1rem',
            color: green[500],
          }}
        >
          <Typography>Data sent successfully!</Typography>
        </Box>
      ) : null}
    </>
  );
};

MatrixForBDAPI.propTypes = {
  allStatusCountDB: PropsType.number.isRequired,
  doneStatusCountDB: PropsType.number.isRequired,
  errorStatusCountDB: PropsType.number.isRequired,
  errorDataDB: PropsType.array.isRequired,
  allStatusCountAPI: PropsType.number.isRequired,
  doneStatusCountAPI: PropsType.number.isRequired,
  errorStatusCountAPI: PropsType.number.isRequired,
  errorDataAPI: PropsType.array.isRequired,
  messages: PropsType.object.isRequired,
  fileName: PropsType.string.isRequired,
  isClicked: PropsType.bool.isRequired,
};
export default MatrixForBDAPI;
