import React from 'react';
import Grid from '@mui/material/Grid';
import PropsType from 'prop-types';
import CAppComponentCard from 'pages/data/addData/CAppComponentCard';
import AppComponentHeader from '@crema/core/AppComponentHeader';
import AppGridContainer from '@crema/core/AppGridContainer';
import BasicDropZone from './BasicDropZone';
import Matrix from './components/Matrix';
import ExportToExcelButton from '@crema/core/AppExportExeclFile';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {green} from '@mui/material/colors';

const AddDataUI = ({
  fileName,
  isClicked,
  allStatusCount,
  setUploadedFiles,
  functionToRun,
  sendDataLoading,
  messages,
  doneStatusCount,
  errorStatusCount,
  uploadedFiles,
  errorsRows,
}) => {
  return (
    <>
      <AppComponentHeader
        title='Upload Data'
        description="Upload new data to your database by drag'n'drop files."
      />

      <AppGridContainer>
        <Grid item xs={12}>
          <CAppComponentCard
            title={''}
            sendDataLoading={sendDataLoading}
            functionToRun={functionToRun}
          >
            <Grid container>
              <Grid item xs={12}>
                <span>
                  make sure that the data is fullName, phone, age,
                  email,country, marks, MPC_4, ...otherData
                </span>
                <BasicDropZone
                  setFiles={setUploadedFiles}
                  files={uploadedFiles}
                />
              </Grid>
              {isClicked ? (
                <Matrix
                  title={messages['addData.DB']}
                  doneStatusCount={doneStatusCount}
                  errorStatusCount={errorStatusCount}
                  allStatusCount={allStatusCount}
                  messages={messages}
                />
              ) : (
                ''
              )}
              {errorStatusCount > 0 ? (
                <ExportToExcelButton
                  fileName={fileName}
                  json_data={errorsRows}
                />
              ) : isClicked ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: 'auto',
                    mt: '1rem',
                    color: green[500],
                  }}
                >
                  <Typography>Data uploaded successfully!</Typography>
                </Box>
              ) : null}
            </Grid>
          </CAppComponentCard>
        </Grid>
      </AppGridContainer>
    </>
  );
};

AddDataUI.propTypes = {
  setUploadedFiles: PropsType.func.isRequired,
  uploadedFiles: PropsType.array.isRequired,
  functionToRun: PropsType.func.isRequired,
  sendDataLoading: PropsType.bool.isRequired,
  isClicked: PropsType.bool.isRequired,
  messages: PropsType.any,
  doneStatusCount: PropsType.number.isRequired,
  errorStatusCount: PropsType.number.isRequired,
  allStatusCount: PropsType.number.isRequired,
  errorsRows: PropsType.array.isRequired,
  fileName: PropsType.string.isRequired,
};

export default AddDataUI;
