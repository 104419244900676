import {useDispatch} from 'react-redux';
import ClientsUI from './EmployeesUI';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useEffect} from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {fetchError} from 'redux/actions';

const EmployeesLG = () => {
  const {messages} = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getEmployees();
  }, [page]);

  const getEmployees = () => {
    jwtAxios
      .get(`/user-jwt?pageNumber=${page}&pageSize=10`)
      .then((e) => {
        setEmployeeCount(e.data.data.dataCount);
        setEmployees(e.data.data.data);
      })
      .catch((e) => dispatch(fetchError(e.messages)));
  };

  const onPageChange = (event, value) => {
    console.log(value);
    setPage(value);
  };

  const OpenAddEmployee = () => {
    navigate('/employees/add-employee');
  };

  const handleDeactivate = async (id) => {
    await jwtAxios
      .put(`/user-jwt/deactivate/${id}`)
      .then((e) => {
        setEmployeeCount(e.data.data.dataCount);
        setEmployees(e.data.data.data);
      })
      .catch((e) => dispatch(fetchError(e.messages)));
    getEmployees();
  };
  return (
    <ClientsUI
      OpenAddEmployee={OpenAddEmployee}
      employeeCount={employeeCount}
      employees={employees}
      messages={messages}
      onPageChange={onPageChange}
      page={page}
      handleDeactivate={handleDeactivate}
    />
  );
};

export default EmployeesLG;
