import {Grid, TextField} from '@mui/material';
import PropTypes from 'prop-types';

const TrackboxForm = ({handleChangeTrackboxData}) => {
  return (
    <Grid itme container spacing={3}>
      <Grid item xs={12} lg={6}>
        <TextField
          id='ai'
          name='ai'
          label='AI'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='ci'
          name='ci'
          label='CI'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='gi'
          name='gi'
          label='GI'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='user_ip'
          name='user_ip'
          label='User Ip'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='trackbox_username'
          name='trackbox_username'
          label='trackbox_username'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='trackbox_password'
          name='trackbox_password'
          label='trackbox_password'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='api_key'
          name='api_key'
          label='api_key'
          variant='outlined'
          onChange={handleChangeTrackboxData}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          id='sub'
          name='sub'
          label='sub'
          variant='outlined'
          onChange={handleChangeTrackboxData}
        />
      </Grid>
    </Grid>
  );
};
TrackboxForm.propTypes = {
  handleChangeTrackboxData: PropTypes.func.isRequired,
};
export default TrackboxForm;
