import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';

// import Alert from '@mui/material/Alert';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import PropsType from 'prop-types';

export default function RowEditControlGrid({
  dataToShow,
  setPageSize,
  pageSize,
  setPageNumber,
  pageNumber,
  rowCount,
  onFilterChange,
  onEditCommit,
  setPatchEdited,
  gettingData,
  handleOnSelect,
  userRole,
}) {
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model);
    setPatchEdited(model);
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {userRole.filter((e) => e == 'admin')[0] && (
          <GridToolbarExport csvOptions={{utf8WithBom: true}} />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <div style={{width: '100%'}}>
        <div style={{width: '100%'}}>
          <DataGrid
            autoHeight
            loading={dataToShow.length === 0 && gettingData}
            checkboxSelection
            rows={dataToShow}
            onSelectionModelChange={handleOnSelect}
            rowCount={rowCount}
            columns={
              dataToShow[0]
                ? Object.keys(dataToShow[0]).map((key) => {
                    return {
                      field: key,
                      headerName: key,
                      editable:
                        key != 'customer_id' || key != 'id' ? true : false,
                    };
                  })
                : []
            }
            onFilterModelChange={onFilterChange}
            editRowsModel={editRowsModel}
            editMode='row'
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
            onEditRowsModelChange={handleEditRowsModelChange}
            page={pageNumber}
            pageSize={pageSize}
            onPageChange={setPageNumber}
            onPageSizeChange={setPageSize}
            paginationMode='server'
            rowsPerPageOptions={[10, 20, 50, 100]}
            onRowEditCommit={onEditCommit}
          />
        </div>
        {/* <Alert severity='info' style={{marginTop: 8}}>
        <code>editRowsModel: {JSON.stringify(editRowsModel)}</code>
      </Alert> */}
      </div>
    </>
  );
}
RowEditControlGrid.propTypes = {
  dataToShow: PropsType.array.isRequired,
  pageSize: PropsType.number.isRequired,
  setPageSize: PropsType.func.isRequired,
  pageNumber: PropsType.number.isRequired,
  setPageNumber: PropsType.func.isRequired,
  rowCount: PropsType.number.isRequired,
  onFilterChange: PropsType.func.isRequired,
  onEditCommit: PropsType.func.isRequired,
  setPatchEdited: PropsType.func.isRequired,
  gettingData: PropsType.bool.isRequired,
  handleOnSelect: PropsType.func.isRequired,
  userRole: PropsType.any.isRequired,
};
