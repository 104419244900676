import jwtAxios, {sseRequest} from '@crema/services/auth/jwt-auth';
import DataUI from './DataUI';
import {useEffect, useState} from 'react';
import {AppInfoView} from '@crema';
import {useDispatch} from 'react-redux';
import {fetchError, showMessage} from 'redux/actions';
import {useAuthUser} from '@crema/utility/AuthHooks';

const DataGLW = () => {
  const dispatch = useDispatch();
  const [rowCount, setRowCount] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selecteCustomer, setSelecteCustomer] = useState(false);

  const [filterValue, setFilterValue] = useState(undefined);
  const [dataToShow, setDataToShow] = useState([]);
  const [patchEdited, setPatchEdited] = useState({});
  const [gettingData, setGettingData] = useState(false);
  const [selected, setSelected] = useState([]);
  const [doneStatusCount, setDoneStatusCount] = useState(0);
  const [allStatusCount, setAllStatusCount] = useState(0);
  const [errorStatusCount, setErrorStatusCount] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [customerId, setCustomerId] = useState(0);
  const [affiliateId, setAffiliateId] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [upLoadingData, setUpLoadingData] = useState(false);
  const user = useAuthUser();

  useEffect(() => {
    if (filterValue?.value) {
      getFilteredValue(pageNumber, pageSize, filterValue);
      return;
    }
    getData(pageNumber, pageSize);
  }, [pageSize, pageNumber, filterValue]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getCustomers(10, 0, searchTerm);
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchTerm]);

  const getData = (pageNumber, pageSize) => {
    setGettingData(true);
    jwtAxios
      .get(`data/allWithOutId?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then((result) => {
        setDataToShow(result.data.data);
        setRowCount(result.data.count);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      })
      .finally(() => {
        setGettingData(false);
      });
  };

  const getFilteredValue = (pageNumber, pageSize, filterValue) => {
    setGettingData(true);

    jwtAxios
      .get(
        `data/filterWithOutId?pageNumber=${pageNumber}&pageSize=${pageSize}&field=${filterValue.columnField}&value=${filterValue.value}&operator=${filterValue.operatorValue}`,
      )
      .then((result) => {
        setDataToShow(result.data.data);
        setRowCount(result.data.count);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      })
      .finally(() => {
        setGettingData(false);
      });
  };

  const deleteData = () => {
    setUpLoadingData(true);
    jwtAxios
      .post(`data/delete-all`, {ids: selected})
      .then(() => getData(pageNumber, pageSize))
      .catch((e) => dispatch(fetchError(e.message)));
  };

  const onFilterChange = (first) => {
    if (!first.items[0]?.value) {
      setFilterValue({...first.items[0]});
      return;
    }
    setFilterValue({...first.items[0]});
    setPageNumber(0);
    setPageSize(20);
  };

  const onEditCommit = (rowId) => {
    var flatingObject = {};
    var neededObject = patchEdited[rowId];
    Object.keys(neededObject).map((key) => {
      flatingObject[key] = neededObject[key].value;
    });
    if (rowId != flatingObject.id) {
      dispatch(fetchError('you can not update id'));
      return;
    }
    jwtAxios
      .put(`data`, flatingObject)
      .then(() => {
        dispatch(
          showMessage('sccusfully data updated id = ' + flatingObject.id),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };
  const handleOnSelect = (values) => {
    setSelected(values);
  };

  const onUploadSubmitting = () => {
    setUpLoadingData(true);
    sseRequest(`data/send-data/${customerId}`, {
      contentType: 'application/json',
      body: JSON.stringify({dataIds: selected}),
      onmessage: (message) => {
        console.log(message);
        if (message.event.includes('doneUploadAPI')) {
          setAllStatusCount((prev) => prev + 1);
          setDoneStatusCount((prev) => prev + 1);
        } else if (message.event.includes('errorUploadAPI')) {
          setErrorStatusCount((prev) => prev + 1);
          setAllStatusCount((prev) => prev + 1);
          setErrorData((prev) => {
            prev.push(remanageDataError(message.data));
            return prev;
          });
        }
      },
      onclose: () => {
        setUpLoadingData(false);
      },
      onerror: (error) => {
        dispatch(fetchError('failed:' + error?.message));
        setUpLoadingData(false);
      },
    });
  };

  const remanageDataError = (string) => {
    const jsonParsed = JSON.parse(string);
    const data = JSON.parse(jsonParsed.data);
    data.error = jsonParsed.error;
    return data;
  };

  const getCustomers = (pageSize, pageNumber, search) => {
    const url = `customer/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setAffiliateId(
          e.data.data.data.map((item) => {
            return {
              label: `${item.user_name}${
                item.integration_type == 'trackbox'
                  ? ' - ' + JSON.parse(item.additional_data_integration)?.ai
                  : ''
              }`,
              key: item.id,
            };
          }),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  const onDropDownChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setCustomerId(valueSelected.key);
      setSelecteCustomer(true);
    } else {
      // Handle the case when the value is cleared (valueSelected is null or undefined)
      setCustomerId(null);
      setSelecteCustomer(false); // or any other default or fallback behavior you want when the value is cleared
    }
  };

  const onDropDownSearch = (searchField) => {
    setSearchTerm(searchField.target.value);
  };

  const handleClear = () => {
    setErrorStatusCount(0);
    setAllStatusCount(0);
    setErrorData([]);
    setDoneStatusCount(0);
  };

  return (
    <>
      <AppInfoView />
      <DataUI
        selecteCustomer={selecteCustomer}
        dataToShow={dataToShow}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        rowCount={rowCount}
        onFilterChange={onFilterChange}
        onEditCommit={onEditCommit}
        setPatchEdited={setPatchEdited}
        gettingData={gettingData}
        handleOnSelect={handleOnSelect}
        selected={selected}
        allStatusCount={allStatusCount}
        doneStatusCount={doneStatusCount}
        errorStatusCount={errorStatusCount}
        setShowUploadModal={setShowUploadModal}
        showUploadModal={showUploadModal}
        onUploadSubmitting={onUploadSubmitting}
        onDropDownChange={onDropDownChange}
        onDropDownSearch={onDropDownSearch}
        affiliateId={affiliateId}
        errorData={errorData}
        userRole={user.user.role}
        upLoadingData={upLoadingData}
        handleClear={handleClear}
        deleteData={deleteData}
      />
    </>
  );
};

export default DataGLW;
