import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import ClinetActions from './ClinetActions';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
  width: 100,
  maxWidth: 100,
  overflow: 'hidden',
}));
const TableItem = ({data, deleteCustomer}) => {
  return (
    <TableRow key={data.id} className='item-hover'>
      {Object.keys(data).map((key) => {
        return (
          <StyledTableCell key={data.id + key + ''} align='left' width={1}>
            {data[key]}
          </StyledTableCell>
        );
      })}
      <StyledTableCell align='right'>
        <ClinetActions customerId={data.id} deleteCustomer={deleteCustomer} />
      </StyledTableCell>
    </TableRow>
  );
};

export default TableItem;

TableItem.propTypes = {
  data: PropTypes.object.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
};
