import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';

const ClinetActions = ({customerId, deleteForm}) => {
  const navigate = useNavigate();

  const handleView = () => {
    navigate({pathname: `/forms/in/${customerId}`});
  };

  return (
    <Box>
      <IconButton aria-controls='alpha-menu' aria-haspopup='true'>
        <Button variant='contained' onClick={handleView}>
          View
        </Button>
      </IconButton>
      <IconButton aria-controls='alpha-menu' aria-haspopup='true'>
        <Button variant='contained' onClick={() => deleteForm(customerId)}>
          delete
        </Button>
      </IconButton>
    </Box>
  );
};

ClinetActions.propTypes = {
  customerId: PropTypes.object.isRequired,
  deleteForm: PropTypes.func.isRequired,
};

export default ClinetActions;
