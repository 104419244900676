import {AppAnimate, AppsPagination} from '@crema';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {Fonts} from 'shared/constants/AppEnums';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';
import {LoadingButton} from '@mui/lab';
import CustomForm from './components/CustomForm/CustomForm';
import TrackboxForm from './components/TrackboxForm/TrackBoxForm';
import DataTable from './components/DataTable';
import AttachModal from './components/AttachModal/AttachModal';
import DynamicFormFromURL from '../addClient/components/CustomUrlForm/DynamicFormFromURL';

const ClientUI = ({
  formik,
  integrationType,
  handleSwitchIntegrationType,
  handleCloseModal,
  openConfirmModal,
  description,
  isUpdateing,
  customerData,
  pageNumber,
  pageSize,
  dataCount,
  setUrl,
  setHttpMethod,
  url,
  httpMethod,
  onPageChange,
  setAttachModal,
  openAttachModal,
  customerId,
}) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <AppAnimate animation='transition.slideUpIn' delay={200}>
        <Card>
          <CardHeader
            sx={{
              py: 4,
              pb: 1,
              px: 5,
              display: 'flex',
              alignItems: 'center',
              minHeight: 50,
              boxSizing: 'border-box',
              '& .MuiTypography-h5': {
                fontSize: 14,
                fontWeight: Fonts.BOLD,
                marginBottom: 0.25,
              },
            }}
            // title={title}
            subheader={description}
            root={{
              subheader: {
                fontSize: 13,
              },
            }}
          />

          <CardContent sx={{px: 10, pt: 1}}>
            <Grid container spacing={4} textAlign={'start'}>
              <Grid item textAlign={'start'} xs={12} lg={6}>
                <Box>
                  <>
                    <Typography>User Name*</Typography>
                    <TextField
                      id='user_name'
                      name='user_name'
                      variant='outlined'
                      onChange={formik.handleChange}
                      value={formik.values.user_name}
                      required
                    />
                  </>
                </Box>
              </Grid>
              {integrationType == 'trackbox' && (
                <Grid item textAlign={'start'} xs={12} lg={6}>
                  <Box>
                    <>
                      <Typography>Integration URL*</Typography>

                      <TextField
                        fullWidth
                        id='integration_url'
                        name='integration_url'
                        variant='outlined'
                        onChange={formik.handleChange}
                        value={formik.values.integration_url}
                        required
                      />
                    </>
                  </Box>
                </Grid>
              )}
              <Grid item textAlign={'start'} xs={9} lg={9}>
                <Box>
                  <FormControl>
                    <FormLabel id='integration_type'>
                      integration type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      defaultValue={integrationType}
                      name='radio-buttons-group'
                      onChange={(e) => {
                        handleSwitchIntegrationType(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value='trackbox'
                        control={<Radio />}
                        label='trackbox'
                      />
                      <FormControlLabel
                        value='customCurl'
                        control={<Radio />}
                        label='custom Curl'
                      />
                      <FormControlLabel
                        value='customUrl'
                        control={<Radio />}
                        label='custom Url'
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item container xs={12}>
                {integrationType == 'customCurl' ? (
                  <CustomForm formik={formik} />
                ) : integrationType == 'trackbox' ? (
                  <TrackboxForm formik={formik} />
                ) : integrationType == 'customUrl' ? (
                  <DynamicFormFromURL
                    formik={formik}
                    setHttpMethod={setHttpMethod}
                    httpMethod={httpMethod}
                    setUrl={setUrl}
                    url={url}
                  />
                ) : null}
              </Grid>
            </Grid>

            <Box mt={3}>
              {isUpdateing ? (
                <LoadingButton loading variant='outlined'>
                  update Customer
                </LoadingButton>
              ) : (
                <Button type='submit' variant='contained' color='primary'>
                  update Customer
                </Button>
              )}
            </Box>
            <Box m={3} alignItems={'flex-start'}>
              <Button
                variant={'contained'}
                onClick={() => setAttachModal(true)}
              >
                Attach Data
              </Button>
            </Box>
            {customerData && (
              <Box>
                <AppsPagination
                  rowsPerPage={pageSize}
                  count={dataCount}
                  page={pageNumber}
                  onPageChange={onPageChange}
                />
                <DataTable
                  customerData={customerData}
                  customerId={customerId}
                />
              </Box>
            )}
          </CardContent>
          <ConfirmModal
            handleClose={handleCloseModal}
            open={openConfirmModal}
          />
          <AttachModal
            open={openAttachModal}
            handleClose={() => setAttachModal(false)}
            customerId={customerId}
          />
        </Card>
      </AppAnimate>
    </form>
  );
};
ClientUI.propTypes = {
  formik: PropTypes.object.isRequired,
  openConfirmModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  integrationType: PropTypes.string.isRequired,
  handleSwitchIntegrationType: PropTypes.func.isRequired,
  description: PropTypes.string,
  isUpdateing: PropTypes.bool.isRequired,
  customerData: PropTypes.array.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  dataCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setAttachModal: PropTypes.func.isRequired,
  openAttachModal: PropTypes.bool.isRequired,
  customerId: PropTypes.number.isRequired,
  isGettingCustomerData: PropTypes.bool,
  setHttpMethod: PropTypes.func.isRequired,
  httpMethod: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};
export default ClientUI;
