import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import AccessableURLs from './accessableURLs/accessableURLs';

import HtmlFormGen from './HtmlFormGen/HtmlFormGenLg';
import ViewForms from './View/ViewForms';

export const formsPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/forms/Allfroms',
    element: <AccessableURLs />,
  },

  {
    permittedRole: RoutePermittedRole.admin,
    path: '/forms/HtmlFormGen',
    element: <HtmlFormGen />,
  },

  {
    permittedRole: RoutePermittedRole.admin,
    path: '/forms/in/:id',
    element: <ViewForms />,
  },
];
