import {sseRequest} from '@crema/services/auth/jwt-auth';
import AddDataUI from './AddDataUI';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {AppInfoView} from '@crema';
import {fetchError} from 'redux/actions';
import {useDispatch} from 'react-redux';

const AddDataGL = () => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const {messages} = useIntl();
  const [doneStatusCount, setDoneStatusCount] = useState(0);
  const [allStatusCount, setAllStatusCount] = useState(0);
  const [errorStatusCount, setErrorStatusCount] = useState(0);
  const [errorData, setErrorData] = useState([]);
  const [fileName, setFileName] = useState('');

  const sendFile = async () => {
    console.log();
    if (!uploadedFiles || !uploadedFiles.length) {
      dispatch(fetchError('one file only'));
      return;
    }
    if (uploadedFiles.length > 1) {
      dispatch(fetchError('one file only'));
      return;
    }
    const fileExtention = uploadedFiles[0].name.split('.')[1];
    setFileName(uploadedFiles[0].name.split('.')[0]);
    if (
      !(
        fileExtention.includes('xlsx') ||
        fileExtention.includes('xlsm') ||
        fileExtention.includes('xlsb') ||
        fileExtention.includes('xltx') ||
        fileExtention.includes('xltm') ||
        fileExtention.includes('xls') ||
        fileExtention.includes('xlt') ||
        fileExtention.includes('xls') ||
        fileExtention.includes('xml') ||
        fileExtention.includes('xml') ||
        fileExtention.includes('xlam') ||
        fileExtention.includes('xla') ||
        fileExtention.includes('xlw') ||
        fileExtention.includes('xlr')
      )
    ) {
      dispatch(fetchError('this file not supported'));
      return;
    }
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append('myfile', uploadedFiles[0]);
    setIsLoading(true);
    setIsClicked(true);
    sseRequest('data/upload-file', {
      body: formData,
      onmessage: (message) => {
        console.log(message);
        if (message.event.includes('doneUploadData')) {
          setAllStatusCount((prev) => prev + 1);
          setDoneStatusCount((prev) => prev + 1);
        } else if (message.event.includes('errorUploadData')) {
          setErrorStatusCount((prev) => prev + 1);
          setAllStatusCount((prev) => prev + 1);
          setErrorData((prev) => {
            prev.push(remanageDataError(message.data));
            return prev;
          });
        }
      },
      onclose: () => {
        setIsLoading(false);
      },
      onerror: (error) => {
        dispatch(fetchError('failed:' + error?.message));
        setIsLoading(false);
      },
    });
  };

  const remanageDataError = (string) => {
    const jsonParsed = JSON.parse(string);
    console.log(jsonParsed);
    const data = JSON.parse(jsonParsed.data);
    data.error = jsonParsed.error;
    return data;
  };
  return (
    <>
      <AppInfoView />
      <AddDataUI
        fileName={fileName}
        isClicked={isClicked}
        allStatusCount={allStatusCount}
        sendDataLoading={isLoading}
        setUploadedFiles={setUploadedFiles}
        functionToRun={sendFile}
        messages={messages}
        doneStatusCount={doneStatusCount}
        errorStatusCount={errorStatusCount}
        uploadedFiles={uploadedFiles}
        errorsRows={errorData}
      />
    </>
  );
};
export default AddDataGL;
