import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import AddEmployee from './addEmployee/AddEmployee';
import Employees from './employees/EmployeesLG';

export const employeesPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/employees/all',
    element: <Employees />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/employees/add-employee',
    element: <AddEmployee />,
  },
];
