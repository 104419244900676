import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import AddClient from './addClient/AddClient';
import Clients from './clients/ClientsLG';
import UploadToClient from './UploadToClient/UploadToClientLg';
import ClientGL from './clinet/ClientGL';
export const clientsPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/clients/all',
    element: <Clients />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/clients/add-client',
    element: <AddClient />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/clients/upload-data-to-client',
    element: <UploadToClient />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/clients/:id',
    element: <ClientGL />,
  },
];
