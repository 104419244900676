import jwtAxios, {sseRequest} from '@crema/services/auth/jwt-auth';
import UploadToClientUI from './UploadToClientUI';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {AppInfoView} from '@crema';
import {fetchError} from 'redux/actions';
import {useDispatch} from 'react-redux';

const UploadToClientLg = () => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const {messages} = useIntl();

  // stats for api calling
  const [doneStatusCountAPI, setDoneStatusCountAPI] = useState(0);
  const [allStatusCountAPI, setAllStatusCountAPI] = useState(0);
  const [errorStatusCountAPI, setErrorStatusCountAPI] = useState(0);
  const [errorDataAPI, setErrorDataAPI] = useState([]);

  // stats for DB calling
  const [doneStatusCountDB, setDoneStatusCountDB] = useState(0);
  const [allStatusCountDB, setAllStatusCountDB] = useState(0);
  const [errorStatusCountDB, setErrorStatusCountDB] = useState(0);
  const [errorDataDB, setErrorDataDB] = useState([]);

  const [fileName, setFileName] = useState('');
  const [affiliateId, setAffiliateId] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const [fileSelected, setFileSelected] = useState(false);

  const onDropDownChange = (_, valueSelected) => {
    if (valueSelected && valueSelected.key) {
      setCustomerId(valueSelected.key);
      setFileSelected(true);
    } else {
      // Handle the case when the value is cleared (valueSelected is null or undefined)
      setCustomerId(null);
      setFileSelected(false); // or any other default or fallback behavior you want when the value is cleared
    }
  };

  const onDropDownSearch = (searchField) => {
    setSearchTerm(searchField.target.value);
  };

  const sendFile = async () => {
    if (!uploadedFiles || !uploadedFiles.length) {
      dispatch(fetchError('one file only'));
      return;
    }
    if (uploadedFiles.length > 1) {
      dispatch(fetchError('one file only'));
      return;
    }
    const fileExtention = uploadedFiles[0].name.split('.')[1];
    setFileName(uploadedFiles[0].name.split('.')[0]);
    if (
      !(
        fileExtention.includes('xlsx') ||
        fileExtention.includes('xlsm') ||
        fileExtention.includes('xlsb') ||
        fileExtention.includes('xltx') ||
        fileExtention.includes('xltm') ||
        fileExtention.includes('xls') ||
        fileExtention.includes('xlt') ||
        fileExtention.includes('xls') ||
        fileExtention.includes('xml') ||
        fileExtention.includes('xml') ||
        fileExtention.includes('xlam') ||
        fileExtention.includes('xla') ||
        fileExtention.includes('xlw') ||
        fileExtention.includes('xlr')
      )
    ) {
      dispatch(fetchError('this file not supported'));
      return;
    }
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append('myfile', uploadedFiles[0]);
    setIsLoading(true);
    setIsClicked(true);
    sseRequest(`data/upload-file/send-data/${customerId}`, {
      body: formData,
      onmessage: (message) => {
        if (message.event.includes('doneUploadData')) {
          setDoneStatusCountDB((prev) => prev + 1);
          setAllStatusCountDB((prev) => prev + 1);
        } else if (message.event.includes('errorUploadData')) {
          setErrorStatusCountDB((prev) => prev + 1);
          setErrorDataDB((prev) => {
            prev.push(remanageDataError(message.data));
            return prev;
          });
          setAllStatusCountDB((prev) => prev + 1);
        }

        if (message.event.includes('doneUploadAPI')) {
          setDoneStatusCountAPI((prev) => prev + 1);
          setAllStatusCountAPI((prev) => prev + 1);
        } else if (message.event.includes('errorUploadAPI')) {
          setErrorStatusCountAPI((prev) => prev + 1);
          setErrorDataAPI((prev) => {
            prev.push(remanageDataError(message.data));
            return prev;
          });
          setAllStatusCountAPI((prev) => prev + 1);
        }
      },
      onclose: () => {
        setIsLoading(false);
      },
      onerror: (error) => {
        dispatch(fetchError('failed:' + error?.message));
        setIsLoading(false);
      },
    });
  };

  const remanageDataError = (string) => {
    const jsonParsed = JSON.parse(string);
    const data = JSON.parse(jsonParsed.data);
    data.error = jsonParsed.error;
    return data;
  };

  const getCustomers = (pageSize, pageNumber, search) => {
    const url = `customer/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setAffiliateId(
          e.data.data.data.map((item) => {
            return {
              label: `${item.user_name}${
                item.integration_type == 'trackbox'
                  ? ' - ' + JSON.parse(item.additional_data_integration)?.ai
                  : ''
              }`,
              key: item.id,
            };
          }),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getCustomers(10, 0, searchTerm);
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchTerm]);

  return (
    <>
      <AppInfoView />
      <UploadToClientUI
        fileName={fileName}
        isClicked={isClicked}
        sendDataLoading={isLoading}
        setUploadedFiles={setUploadedFiles}
        uploadedFiles={uploadedFiles}
        functionToRun={sendFile}
        messages={messages}
        allStatusCountDB={allStatusCountDB}
        doneStatusCountDB={doneStatusCountDB}
        errorStatusCountDB={errorStatusCountDB}
        errorDataDB={errorDataDB}
        allStatusCountAPI={allStatusCountAPI}
        doneStatusCountAPI={doneStatusCountAPI}
        errorStatusCountAPI={errorStatusCountAPI}
        errorDataAPI={errorDataAPI}
        affiliateId={affiliateId}
        onDropDownChange={onDropDownChange}
        fileSelected={fileSelected}
        onDropDownSearch={onDropDownSearch}
      />
    </>
  );
};
export default UploadToClientLg;
