import React from 'react';
import Grid from '@mui/material/Grid';
import PropsType from 'prop-types';
import CAppComponentCard from 'pages/clients/UploadToClient/CAppComponentCard';
import AppComponentHeader from '@crema/core/AppComponentHeader';
import AppGridContainer from '@crema/core/AppGridContainer';
import BasicDropZone from './BasicDropZone';
import MatrixForBDAPI from './components/MatrixForBDAPI';

const AddDataUI = ({
  fileName,
  isClicked,
  setUploadedFiles,
  functionToRun,
  sendDataLoading,
  messages,
  uploadedFiles,
  fileSelected,
  affiliateId,
  onDropDownChange,
  onDropDownSearch,
  allStatusCountDB,
  doneStatusCountDB,
  errorStatusCountDB,
  errorDataDB,
  allStatusCountAPI,
  doneStatusCountAPI,
  errorStatusCountAPI,
  errorDataAPI,
}) => {
  return (
    <>
      <AppComponentHeader
        title='Upload Data To Client'
        description="Upload new data To Clinet And To Your Database by drag'n'drop files."
      />

      <AppGridContainer>
        <Grid item xs={12}>
          <CAppComponentCard
            title={''}
            fileSelected={fileSelected}
            sendDataLoading={sendDataLoading}
            functionToRun={functionToRun}
            affiliateId={affiliateId}
            onDropDownChange={onDropDownChange}
            onDropDownSearch={onDropDownSearch}
          >
            <Grid container>
              <Grid item xs={12}>
                <span>
                  make sure that the data is fullName, phone, age,
                  email,country, marks, MPC_4, ...otherData
                </span>
                <BasicDropZone
                  setFiles={setUploadedFiles}
                  files={uploadedFiles}
                />
              </Grid>
              <MatrixForBDAPI
                allStatusCountDB={allStatusCountDB}
                doneStatusCountDB={doneStatusCountDB}
                errorStatusCountDB={errorStatusCountDB}
                errorDataDB={errorDataDB}
                allStatusCountAPI={allStatusCountAPI}
                doneStatusCountAPI={doneStatusCountAPI}
                errorStatusCountAPI={errorStatusCountAPI}
                errorDataAPI={errorDataAPI}
                messages={messages}
                fileName={fileName}
                isClicked={isClicked}
              />
            </Grid>
          </CAppComponentCard>
        </Grid>
      </AppGridContainer>
    </>
  );
};

AddDataUI.propTypes = {
  setUploadedFiles: PropsType.func.isRequired,
  uploadedFiles: PropsType.array.isRequired,
  functionToRun: PropsType.func.isRequired,
  sendDataLoading: PropsType.bool.isRequired,
  fileSelected: PropsType.bool.isRequired,
  isClicked: PropsType.bool.isRequired,
  messages: PropsType.any,
  fileName: PropsType.string.isRequired,
  affiliateId: PropsType.array.isRequired,
  onDropDownChange: PropsType.func.isRequired,
  onDropDownSearch: PropsType.func.isRequired,
  allStatusCountDB: PropsType.number.isRequired,
  doneStatusCountDB: PropsType.number.isRequired,
  errorStatusCountDB: PropsType.number.isRequired,
  errorDataDB: PropsType.array.isRequired,
  allStatusCountAPI: PropsType.number.isRequired,
  doneStatusCountAPI: PropsType.number.isRequired,
  errorStatusCountAPI: PropsType.number.isRequired,
  errorDataAPI: PropsType.array.isRequired,
};

export default AddDataUI;
