import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import AppTableContainer from '../../../../@crema/core/AppTableContainer';

const EmployeesTable = ({employees, handleDeactivate}) => {
  return (
    <AppTableContainer>
      <Table stickyHeader className='table'>
        <TableHead>
          <TableHeading
            headings={Object.keys(employees[0] ? employees[0] : {})}
          />
        </TableHead>
        <TableBody>
          {employees.map((data) => (
            <TableItem
              data={data}
              key={data.id}
              handleDeactivate={handleDeactivate}
            />
          ))}
        </TableBody>
      </Table>
    </AppTableContainer>
  );
};

export default EmployeesTable;

EmployeesTable.defaultProps = {
  employees: [],
};

EmployeesTable.propTypes = {
  employees: PropTypes.array,
  handleDeactivate: PropTypes.func,
};
