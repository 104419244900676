import React from 'react';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';

const ModalButton = ({handleClose, setIsSubmitted, setHtmlCode}) => {
  return (
    <>
      <Button
        type='submit'
        variant='contained'
        sx={{
          backgroundColor: 'blue', // Change according to your needs
          color: 'white',
          marginRight: '1rem', // Spacing between the buttons
        }}
        onClick={() => {
          handleClose(false);
        }}
      >
        Hide
      </Button>
      <Button
        type='submit'
        variant='contained'
        sx={{
          backgroundColor: 'green', // Change according to your needs
          color: 'white',
        }}
        onClick={() => {
          handleClose(false);
          setIsSubmitted(false);
          setHtmlCode('');
        }}
      >
        Apply
      </Button>
    </>
  );
};

ModalButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setIsSubmitted: PropTypes.func.isRequired,
  setHtmlCode: PropTypes.func.isRequired,
};

export default ModalButton;
