import jwtAxios from '@crema/services/auth/jwt-auth';
import { Autocomplete, Box, Button, Chip, Modal, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AttachModal = ({ open, handleClose, customerId }) => {
  const [values, setValues] = useState();
  const handleQuestionChange = (a) => {
    setValues(a.target.value);
  };

  const dispatch = useDispatch();
  const handleOk = async () => {
    console.log(customerId);
    await jwtAxios.post(`data/archef-data/${customerId}`, {
      dataIds: [...values]
    }).then(() =>
      window.location.reload()
    ).catch(e =>
      dispatch(fetchError(e.message))
    );
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
        onClose={() => handleClose()}
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id='parent-modal-title'>customer add data</h2>
          <Autocomplete
            multiple
            id='tags-outlined'
            options={[]}
            freeSolo
            onChange={(event, newValue) =>
              handleQuestionChange(
                { target: { value: newValue } }
              )
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                  key={index}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Options'
              />
            )}
          />
          <Button
            variant='contained'
            onClick={async () => {
              await handleOk();
            }}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};
AttachModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired
};
export default AttachModal;
