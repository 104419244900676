import React from 'react';
import {utils, write} from 'xlsx';
import {saveAs} from 'file-saver';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';

function ExportToExcelButton({json_data, fileName}) {
  // Get current date
  const date = new Date();
  const dateString = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  // Combine fileName and dateString
  const finalFileName = `${fileName}_Report_${dateString}.xlsx`;
  const exportJsonToExcel = () => {
    const ws = utils.json_to_sheet(json_data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = write(wb, {bookType: 'xlsx', type: 'binary'});

    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;

    saveAs(new Blob([buf], {type: 'application/octet-stream'}), finalFileName);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ml: 'auto',
        mt: '1rem',
      }}
    >
      <Button variant='contained' color='primary' onClick={exportJsonToExcel}>
        Export Error Report to Excel
      </Button>
    </Box>
  );
}
ExportToExcelButton.propTypes = {
  json_data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportToExcelButton;
