import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import EmployeesActions from './EmployeesActions';
import {styled} from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 15,
  '&:first-of-type': {
    paddingLeft: 30,
  },
  '&:last-of-type': {
    paddingRight: 30,
  },
}));
const TableItem = ({data, handleDeactivate}) => {
  return (
    <TableRow key={data.name} className='item-hover'>
      {Object.keys(data).map((key) => {
        return (
          <StyledTableCell key={data.id + key + ''} align='left'>
            {data[key]}
          </StyledTableCell>
        );
      })}
      <StyledTableCell align='right'>
        <EmployeesActions
          handleDeactivate={handleDeactivate}
          dataId={data.id}
        />
      </StyledTableCell>
    </TableRow>
  );
};

export default TableItem;

TableItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleDeactivate: PropTypes.func.isRequired,
};
