import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const DynamicFormFromURL = ({ setHttpMethod, httpMethod, url, setUrl }) => {
  const [formFields, setFormFields] = useState([]);

  console.log(url);
  console.log(httpMethod);
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };
  const handleHttpMethodChange = (event) => {
    setHttpMethod(event.target.value);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  useEffect(() => {
    if (!url || !isValidUrl(url)) {
      setFormFields([]);
      return;
    }
    parseUrlAndGenerateFields();
  }, [url]);

  const parseUrlAndGenerateFields = () => {
    if (!url) return;
    try {
      const params = new URLSearchParams(new URL(url).search);
      const fields = Array.from(params.keys()).map((key) => {
        const value = decodeURIComponent(params.get(key));
        return {
          name: key,
          value: value,
          placeholder: value, // Store the decoded placeholder
        };
      });
      setFormFields(fields);
    } catch (error) {
      console.error('Invalid URL:', error);
      // Handle invalid URL case here (optional)
    }
  };

  const handleFieldChange = (fieldName, newValue) => {
    // Replace '&' with an empty string or another character of your choice
    const sanitizedValue = newValue.replace(/&/g, '');

    const updatedFields = formFields.map((field) =>
      field.name === fieldName ? { ...field, value: sanitizedValue.trim() } : field,
    );

    setFormFields(updatedFields);
    updateUrl(updatedFields);
  };

  const updateUrl = (fields) => {
    const baseUrl = new URL(url).origin + new URL(url).pathname;
    let queryString = '?';

    fields.forEach((field, index) => {
      // Construct the new parameter value
      const paramValue =
        field.value !== field.placeholder ? field.value : field.placeholder;
      // Append the parameter to the query string without encoding the space
      queryString += `${index > 0 ? '&' : ''}${field.name}=${paramValue}`;
    });

    const newUrl = baseUrl + queryString;
    setUrl(newUrl);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box>
          <Typography mb={4}>User Input URL</Typography>
          <TextField
            fullWidth
            label='URL'
            value={url}
            onChange={handleUrlChange}
            variant='outlined'
          />
        </Box>
        <Typography mb={4}>
          {
            'please make sure to replace the neeeded data in ${first_name} ${last_name} ${full_name} ${age} ${phone} ${id} ${email} ${country} ${country_iso_2} ${country_iso_3} ${country_name} &{country_phone_code} ${MPC_4} ${marks} ${other_data}  '
          }
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="http-method-label">HTTP Method</InputLabel>
          <Select
            labelId="http-method-label"
            id="http-method-select"
            value={httpMethod}
            label="HTTP Method"
            onChange={handleHttpMethodChange}
          >
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {formFields.map((field, index) => (
        <Grid item xs={12} lg={6} key={index}>
          <TextField
            fullWidth
            label={field.name}
            value={field.value}
            onChange={(event) =>
              handleFieldChange(field.name, event.target.value)
            }
            variant='outlined'
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicFormFromURL;
DynamicFormFromURL.propTypes = {
  setUrl: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  setHttpMethod: PropTypes.func.isRequired,
  httpMethod: PropTypes.string.isRequired,

};
