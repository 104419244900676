import React from 'react';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from '../../../../shared/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {fetchError, showMessage} from 'redux/actions';
import {useAuthUser} from '@crema/utility/AuthHooks';
import jwtAxios from '@crema/services/auth/jwt-auth';
import PasswordEncrypter from '@crema/utility/PasswordEncrypter';

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .required('New password required.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  retypeNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const {user} = useAuthUser();
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        <IntlMessages id='common.changePassword' />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          userId: user.uid,
          newPassword: null,
          retypeNewPassword: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setSubmitting}) => {
          setSubmitting(true);
          if (
            !data.newPassword ||
            data.newPassword == '' ||
            data.newPassword == null
          ) {
            dispatch(fetchError('new password must be filled'));
          }
          jwtAxios
            .put(`user-jwt/update-password/${data.userId}`, {
              password: PasswordEncrypter(data.newPassword),
            })
            .then(() => dispatch(showMessage('password changed')))
            .catch((e) => dispatch(fetchError(e.message)))
            .finally(() => setSubmitting(false));
        }}
      >
        {() => <ChangePasswordForm />}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
