import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import AppTableContainer from '../../../../@crema/core/AppTableContainer';

const CustomerTable = ({customers, deleteForm}) => {
  return (
    <AppTableContainer>
      <Table stickyHeader className='table'>
        <TableHead>
          <TableHeading
            headings={Object.keys(customers[0] ? customers[0] : {})}
          />
        </TableHead>
        <TableBody>
          {customers.map((data) => (
            <TableItem data={data} key={data.id} deleteForm={deleteForm} />
          ))}
        </TableBody>
      </Table>
    </AppTableContainer>
  );
};

export default CustomerTable;

CustomerTable.defaultProps = {
  customers: [],
};

CustomerTable.propTypes = {
  customers: PropTypes.array,
  deleteForm: PropTypes.func.isRequired,
};
