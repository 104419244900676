import {useState} from 'react';
import AddClientUI from './AddClientUI';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {AppInfoView} from '@crema';
import {useDispatch} from 'react-redux';
import {fetchError} from 'redux/actions';
import {isHttpValid} from 'utils';

const AddClient = () => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [formData, updateFormData] = useState();
  const [formTrackbooxData, updateFormTrackbooxData] = useState();
  const [urlCode, setUrl] = useState('');
  const [curlCode, setCurl] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET'); // State to store the HTTP method

  const [integration_type, setIntegration_type] = useState('trackbox');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const setCurlCode = (e) => {
    setCurl(e.target.value);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let additional_data_integration =
      integration_type == 'customCurl'
        ? {curl: curlCode}
        : integration_type == 'trackbox'
        ? formTrackbooxData
        :integration_type == 'customUrl'
        ?  {url: urlCode,method:httpMethod}
        : null;
    if (
      integration_type != 'customCurl' && integration_type!= 'customUrl' &&
      !isHttpValid(formData.integration_url)
    ) {
      dispatch(fetchError('url not valid'));
      return;
    }
    setIsAdding(true);
    jwtAxios
      .post('customer', {
        user_name: formData.user_name,
        integration_url: formData.integration_url,
        integration_type,
        additional_data_integration,
      })
      .then(() => {
        setOpenConfirmModal(true);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleChangeTrackboxData = (e) => {
    updateFormTrackbooxData({
      ...formTrackbooxData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <>
      <AppInfoView />
      <AddClientUI
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isAdding={isAdding}
        setIntegration_type={setIntegration_type}
        integration_type={integration_type}
        setCurlCode={setCurlCode}
        setUrl={setUrl}
        setHttpMethod={setHttpMethod}
        urlCode={urlCode}
        httpMethod={httpMethod}
        handleChangeTrackboxData={handleChangeTrackboxData}
        handleCloseModal={handleCloseModal}
        openConfirmModal={openConfirmModal}
      />
    </>
  );
};

export default AddClient;
