import {useParams} from 'react-router-dom';
import FormPage from '../HtmlFormGen/component/formPage';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {useState} from 'react';

const ViewForms = () => {
  const {id} = useParams();
  const [formHieght, setFormHeight] = useState();

  return (
    <>
      {/* ... */}
      <Box marginBottom={2}>
        <Card>
          <CardHeader subheader='Form Information' />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  FORM ID : {id} {/* Displaying FORM ID and id inline */}
                </Typography>
              </Grid>
            </Grid>

            <Box marginBottom={2}>
              <Typography>Form IFrame Tag</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    value={`<iframe src="https://www.blackdawn.info/forms/${id}" scrolling="no" width="100%" height="${formHieght}px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <FormPage disabled setFormHeight={setFormHeight} />
    </>
  );
};

export default ViewForms;
