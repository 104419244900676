import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const ClinetActions = ({customerId, deleteCustomer}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleView = () => {
    navigate({pathname: `/clients/${customerId}`});
  };
  const handleDelete = () => {
    deleteCustomer(customerId);
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls='alpha-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='alpha-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem style={{fontSize: 14}} onClick={handleView}>
          View
        </MenuItem>
        <MenuItem style={{fontSize: 14}} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
ClinetActions.propTypes = {
  customerId: PropTypes.object.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
};

export default ClinetActions;
