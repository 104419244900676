import React, {useEffect, useState} from 'react';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import jwtAxios from '@crema/services/auth/jwt-auth';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import {IntlProvider} from 'react-intl';
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
const FormPage = ({disabled, setFormHeight}) => {
  const [formValues, setFormValues] = useState({});
  const [formData, setFormData] = useState();
  const [allData, setAllData] = useState();
  const [lang, setLang] = useState('');
  const idDisabled = disabled;
  const [openModal, setOpenModal] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const {width} = useWindowSize();
  const [modalMessage, setModalMessage] = useState('');
  const [errorField, setErrorField] = useState({});
  const isRtl = lang === 'ar' ? 'rtl' : 'ltr';
  const [labeledFields, setLabeledFields] = useState({
    'First Name': '',
    'Last Name': '',
    Email: '',
    'Phone Number': '',
    age: '',
    submit: '',
    ok: '',
    ModalTitle: '',
    ModalDec: '',
    ModalDec1: '',
    alreadyExist: '',
    somethingWrong: '',
    handleMessageRequier: '',
    pleaseAddAValidNumber: '',
    pleaseenteravalidemail: '',
  });
  const labeledArabicFields = {
    'First Name': 'الإسم الاول',
    'Last Name': 'إسم العائلة',
    Email: 'البريد الإلكتروني',
    'Phone Number': 'رقم الهاتف',
    age: 'العمر',
    submit: 'إرسال',
    ModalTitle: '! شكرا لك على تسجيل بياناتك',
    ModalDec: '. تم استلام معلوماتك الشخصية بنجاح',
    ModalDec1: '. سيتواصل معك مندوب الخدمة في غضون 48 ساعة',
    ok: 'موافق',
    alreadyExist: 'معلوماتك موجودة بالفعل , سيتم التواصل معك في أقرب وقت',
    somethingWrong: '!حدث خطأ ما',
    handleMessageRequier: 'يرجى ملء هذا الحقل',
    pleaseAddAValidNumber: 'يرجى تسجيل رقم الهاتف',
    pleaseenteravalidemail: 'please enter a valid email',
  };
  const labeledEnglishFields = {
    'First Name': 'First Name',
    'Last Name': 'Last name',
    Email: 'Email',
    'Phone Number': 'Phone Number',
    age: 'age',
    submit: 'submit',
    ModalTitle: 'Thank you for registering your details!',
    ModalDec: 'تم استلام معلوماتك الشخصية بنجاح.',
    ModalDec1: 'The service representative will contact you within 48 hours.',
    ok: 'Done',
    alreadyExist:
      'Your information is already there, we will contact you as soon as possible.',
    somethingWrong: 'something went wrong !',
    handleMessageRequier: 'please fill this field',
    pleaseAddAValidNumber: 'please enter a valid phone number',
    pleaseenteravalidemail: 'please enter a valid email',
  };

  useEffect(() => {
    if (setFormHeight) {
      var body = document.body,
        html = document.documentElement;
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      setFormHeight(height);
    }
  }, [formData]);
  const {id} = useParams();

  const cardStyle = {
    marginBottom: '20px',
  };
  const lgCardStyle = {
    marginBottom: '20px',
    padding: '10px',
  };
  const OutcardStyle = {
    marginBottom: '20px',
    border: `3px solid ${formData?.buttonColor}`, // Added border style
  };
  useEffect(() => {
    jwtAxios.get(`forms/${id}`).then((e) => {
      setAllData(e.data?.data);
      setFormData(e.data?.data?.form_info);
      setFormValues({'Phone Number': e.data?.data?.form_info.country.prefix});
      if (e.data?.data.form_info.language == 'Arabic') {
        setLang('ar');
        setLabeledFields({...labeledArabicFields});
      } else {
        setLang('en');
        setLabeledFields({...labeledEnglishFields});
      }
    });
  }, []);
  const handlePhoneInputChange = (value) => {
    setFormValues({...formValues, 'Phone Number': value});
  };
  const handleInputChange = (event) => {
    const {name, value} = event.target;
    if (name == 'Phone Number' && !value.includes(formData?.country?.prefix)) {
      setFormValues({...formValues, [name]: formData?.country?.prefix});
      return;
    }
    setFormValues({...formValues, [name]: value});
    document.getElementById(event.target.name)
      ? document.getElementById(event.target.name)?.setCustomValidity('')
      : null;
  };

  const handleQuestionChange = (event, questionIndex, questionText) => {
    const {value} = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [`question_${questionIndex}_${questionText}`]: value,
    }));
    document.getElementById(event.target.name)
      ? document.getElementById(event.target.name)?.setCustomValidity('')
      : null;
  };

  const validateFormValue = () => {
    var emailValidation =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    var phoneValidation =
      /^(?:(?:\+?\d{1,3}|0)(?:\s|-)?\(?(?:\d{1,4}\)?(?:\s|-)?\d{1,4})(?:\s|-)?\d{1,4}(?:\s*(?:#|x|ext)\s*\d{1,5})?)$/;

    if (formValues.Email && !formValues.Email.match(emailValidation)) {
      setErrorField((prev) => {
        return {...prev, Email: 'please enter a valid email'};
      });
      return false;
    } else {
      setErrorField((prev) => {
        return {...prev, Email: false};
      });
    }

    // phone validation remove when done
    if (
      formValues['Phone Number'] &&
      !formValues['Phone Number'].match(phoneValidation)
    ) {
      setErrorField((prev) => {
        return {...prev, 'Phone Number': labeledFields.pleaseAddAValidNumber};
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let valid = validateFormValue();
    if (!valid) {
      return;
    }

    let answer = Object.keys(formValues)
      .filter((i) => i.includes('question_'))
      .map((i) => {
        return {question: i, answer: formValues[i]};
      });
    jwtAxios
      .post(`data-rciver/recive-data`, {
        fullName: `${formValues['First Name']} ${formValues['Last Name']}`,
        phone: `${formValues['Phone Number'].replace('+', '')}`,
        age: formValues['age'] ? formValues['age'] : '100',
        email: formValues['Email'] ? `${formValues['Email']}` : null,
        country: `${allData?.country}`,
        marks: allData.marks,
        MPC_4: allData.MPC_4,
        formId: id,
        other_data: {data: answer},
      })
      .then(() => {
        setModalMessage('Thank you for your submission!');
        setShowLabel(false);
        setOpenModal(true);
      })
      .catch((e) => {
        if (
          e.response.data.status == '400' &&
          e.response.data.message.includes('Duplicate')
        ) {
          setModalMessage(labeledFields['alreadyExist']);
        } else {
          setModalMessage(labeledFields['somethingWrong']);
        }
        setShowLabel(true);
      });
  };

  if (!formData) {
    return '';
  }

  return (
    <>
      <div style={{direction: isRtl}}>
        <form onSubmit={handleSubmit}>
          <Card style={OutcardStyle}>
            <CardContent>
              {/* Add the title here */}
              <Typography
                variant='h4'
                align='center'
                style={{
                  marginBottom: '30px',
                  fontWeight: 'bold',
                  fontSize: '24px',
                }}
              >
                {formData.headerTitle}
              </Typography>

              <Grid container>
                {['First Name', 'Last Name'].map((fieldName, index) => {
                  const field = formData.fields.find(
                    (f) => f.name === fieldName,
                  );
                  return (
                    <Grid key={index} item xs={12} lg={6}>
                      <Typography
                        variant='body1'
                        fontSize='18px'
                        color={formData.labelsColor}
                      >
                        {labeledFields[field.name]} {field.required ? '*' : ''}
                      </Typography>
                      <TextField
                        disabled={idDisabled}
                        required={field.required}
                        name={field.name}
                        id={field.name}
                        label={field.name}
                        variant='outlined'
                        fullWidth
                        error={errorField[field.name]}
                        aria-errormessage={'errorField[field.name]'}
                        style={width > 1024 ? lgCardStyle : cardStyle}
                        InputLabelProps={{
                          shrink: false,
                          style: {visibility: 'hidden'},
                        }}
                        onChange={handleInputChange}
                        onInvalid={() => {
                          document
                            ?.getElementById(field.name)
                            ?.setCustomValidity(
                              labeledFields['handleMessageRequier'],
                            );
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              {/* The rest of the fields and code remains the same as in your original code */}
              {formData.fields
                .filter(
                  (field) => !['First Name', 'Last Name'].includes(field.name),
                )
                .map((field, index) => (
                  <div key={index}>
                    <Typography
                      variant='body1'
                      fontSize='18px'
                      color={formData.labelsColor}
                    >
                      {labeledFields[field.name]} {field.required ? '*' : ''}
                    </Typography>
                    {field.name == 'Phone Number' ? (
                      <MuiPhoneNumber
                        dir='ltr'
                        defaultCountry={'us'}
                        onChange={handlePhoneInputChange}
                        disabled={idDisabled}
                        variant='outlined'
                        fullWidth
                        id={field.name}
                        required={field.required}
                        error={errorField[field.name]}
                        helperText={
                          errorField[field.name] ? errorField[field.name] : null
                        }
                        value={formValues[field.name]}
                        style={width > 1024 ? lgCardStyle : cardStyle}
                        InputLabelProps={{
                          shrink: false,
                          style: {visibility: 'hidden'},
                        }}
                        onInvalid={() => {
                          document
                            ?.getElementById(field.name)
                            ?.setCustomValidity(
                              labeledFields['handleMessageRequier'],
                            );
                        }}
                      />
                    ) : (
                      <TextField
                        disabled={idDisabled}
                        required={field.required}
                        name={field.name}
                        label={field.name}
                        variant='outlined'
                        fullWidth
                        error={errorField[field.name]}
                        aria-errormessage={'errorField[field.name]'}
                        style={width > 1024 ? lgCardStyle : cardStyle}
                        InputLabelProps={{
                          shrink: false,
                          style: {visibility: 'hidden'},
                          required: field.required,
                        }}
                        id={field.name}
                        onChange={handleInputChange}
                        onInvalid={() => {
                          document
                            ?.getElementById(field.name)
                            ?.setCustomValidity(
                              labeledFields['handleMessageRequier'],
                            );
                        }}
                      ></TextField>
                    )}
                  </div>
                ))}
              {formData.showEmail && (
                <FormControl variant='outlined' fullWidth style={cardStyle}>
                  <Typography
                    variant='body1'
                    fontSize='18px'
                    color={formData.labelsColor}
                  >
                    {labeledFields['Email']}
                    {formData.emailRequired ? '*' : ''}
                  </Typography>
                  <TextField
                    disabled={idDisabled}
                    required={formData.emailRequired}
                    id='Email'
                    name='Email'
                    error={errorField['Email']}
                    helperText={
                      errorField['Email'] ? errorField['Email'] : null
                    }
                    variant='outlined'
                    onChange={handleInputChange}
                    onInvalid={() => {
                      document
                        ?.getElementById('Email')
                        ?.setCustomValidity(
                          labeledFields['handleMessageRequier'],
                        );
                    }}
                  />
                </FormControl>
              )}
              {formData.showAge ? (
                <FormControl variant='outlined' fullWidth style={cardStyle}>
                  <Typography
                    variant='body1'
                    fontSize='18px'
                    color={formData.labelsColor}
                  >
                    {labeledFields['age']}
                    {formData.ageRequired ? '*' : ''}
                  </Typography>
                  {formData.ageType === 'text' ? (
                    <TextField
                      disabled={idDisabled}
                      required={formData.ageRequired}
                      id='age'
                      name='age'
                      label='Age'
                      variant='outlined'
                      InputLabelProps={{
                        shrink: false,
                        style: {visibility: 'hidden'},
                      }}
                      onChange={handleInputChange}
                      onInvalid={() => {
                        document
                          ?.getElementById('age')
                          ?.setCustomValidity(
                            labeledFields['handleMessageRequier'],
                          );
                      }}
                    />
                  ) : (
                    <>
                      <InputLabel
                        disabled={idDisabled}
                        id='demo-simple-select-label'
                        style={{visibility: 'hidden'}}
                      >
                        {labeledFields['age']}
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        onChange={handleInputChange}
                        required
                        inputProps={{
                          id: 'age',
                          name: 'age',
                          onInvalid: () => {
                            document.getElementById('age')
                              ? document
                                  .getElementById('age')
                                  ?.setCustomValidity(
                                    labeledFields['handleMessageRequier'],
                                  )
                              : null;
                          },
                        }}
                      >
                        {formData.ageRanges &&
                          formData.ageRanges.map((range, i) => (
                            <MenuItem key={i} value={`${range[0]}-${range[1]}`}>
                              {range[0]}-{range[1]}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              ) : (
                ''
              )}
              {formData.questions &&
                formData.questions.map((question, questionIndex) => (
                  <FormControl
                    key={questionIndex}
                    variant='outlined'
                    fullWidth
                    style={width > 1024 ? lgCardStyle : cardStyle}
                  >
                    <Typography
                      variant='body1'
                      fontSize='18px'
                      color={formData.labelsColor}
                    >
                      {question.question}
                      {question.required ? '*' : ''}
                    </Typography>
                    {question.type === 'text' ? (
                      <TextField
                        id={question.question}
                        name={question.question}
                        disabled={idDisabled}
                        variant='outlined'
                        required={question.required} // Add this line
                        InputLabelProps={{
                          shrink: false,
                          style: {visibility: 'hidden'},
                        }}
                        onInvalid={() => {
                          document
                            .getElementById(question.question)
                            .setCustomValidity(
                              labeledFields['handleMessageRequier'],
                            );
                        }}
                        onChange={(event) =>
                          handleQuestionChange(
                            event,
                            questionIndex,
                            question.question,
                          )
                        }
                      />
                    ) : (
                      <>
                        <InputLabel
                          disabled={idDisabled}
                          id={`select-${questionIndex}`}
                          style={{visibility: 'hidden'}}
                        >
                          {question.question}
                        </InputLabel>
                        <Select
                          labelId={`select-${questionIndex}`}
                          required={question.required} // Add this line
                          onChange={(event) =>
                            handleQuestionChange(
                              event,
                              questionIndex,
                              question.question,
                            )
                          }
                        >
                          {question.options &&
                            question.options.map((option, j) => (
                              <MenuItem key={j} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                        </Select>
                      </>
                    )}
                  </FormControl>
                ))}
              {showLabel ? (
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  fontSize='20px'
                  color='red'
                >
                  {modalMessage}
                </Typography>
              ) : (
                <Typography variant='body1' color='red'>
                  <br />
                </Typography>
              )}
              <Button
                disabled={idDisabled}
                type='submit'
                variant='contained'
                style={{
                  backgroundColor: formData.buttonColor,
                  color: formData.buttonTextColor,
                  width: 'auto', // Auto width based on content
                  minWidth: '30%', // Minimum width
                  margin: '0 auto', // Center the button horizontally
                  display: 'block', // Make it a block element
                  fontSize: '20px', // Font size
                  fontWeight: 'bold', // Font weight
                  padding: '10px 20px', // Padding to make button bigger and wider than text
                  whiteSpace: 'nowrap', // Ensures text stays on one line
                }}
              >
                {formData.buttonText}
              </Button>
            </CardContent>
          </Card>
        </form>
      </div>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md' // Increase the max width of the dialog
        fullWidth={true} // Make the dialog take up the full width of the container
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{textAlign: 'center', fontSize: '24px'}}
        >
          <Typography variant='h1' component='div'>
            {' '}
            {labeledFields['ModalTitle']}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{textAlign: 'center'}}
          >
            <Typography variant='body1'>
              {' '}
              {labeledFields['ModalDec']}
            </Typography>
          </DialogContentText>
          <DialogContentText
            id='alert-dialog-description'
            style={{textAlign: 'center'}}
          >
            <Typography variant='body1'>
              {' '}
              {labeledFields['ModalDec1']}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenModal(false)}
            style={{
              backgroundColor: formData.buttonColor,
              color: formData.buttonTextColor,
              width: '30%', // Set the width as per your requirement
              margin: '0 auto', // Center the button horizontally
              display: 'block', // Make it a block element so that it takes the full width
              fontSize: '20px', // Adjust the font size as per your requirement
              fontWeight: 'bold',
              padding: '10px 0', // Padding to make the button bigger
            }}
          >
            {labeledFields['ok']}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FormPage.propTypes = {
  formData: PropTypes.object,
  disabled: PropTypes.bool,
  setFormHeight: PropTypes.func,
};

export default FormPage;
