import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
const PasswordEncrypter = (message) => {

const  privateKey=process.env.REACT_APP_API_KEY; 
console.log(privateKey);
const hashDigest = sha256(message);
const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, privateKey));
return hmacDigest;
};
export default PasswordEncrypter;
