import {Button} from '@mui/material';
import AppsHeader from '@crema/core/AppsContainer/AppsHeader';
import Box from '@mui/material/Box';
import {LoadingButton} from '@mui/lab';
import PropsType from 'prop-types';

const UploadButton = ({isLoading, functionToRun}) => {
  return (
    <>
      <AppsHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: 'auto',
            }}
          >
            {isLoading ? (
              <LoadingButton loading variant='outlined'>
                Upload Files
              </LoadingButton>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={functionToRun}
              >
                Upload Files
              </Button>
            )}
          </Box>
        </Box>
      </AppsHeader>
    </>
  );
};

UploadButton.propTypes = {
  isLoading: PropsType.bool.isRequired,
  functionToRun: PropsType.func.isRequired,
};

export default UploadButton;
