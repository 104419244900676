import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Button} from '@mui/material';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {useDispatch} from 'react-redux';
import {fetchError} from 'redux/actions';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
  width: 100,
  maxWidth: 100,
  overflow: 'hidden',
}));
const TableItem = ({data, customerId}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    console.log(customerId, data.id);
    jwtAxios
      .delete(`data/${customerId}`, {data: {dataIds: data.id}})
      .then(() => {
        window.location.reload();
      })
      .catch((e) => dispatch(fetchError(e.message)));
  };
  return (
    <TableRow key={data.id} className='item-hover'>
      {Object.keys(data).map((key) => {
        return (
          <StyledTableCell key={data.id + key + ''} align='left' width={1}>
            {data[key]}
          </StyledTableCell>
        );
      })}
      <StyledTableCell>
        <Button onClick={handleDelete} variant='contained'>
          Delete
        </Button>
      </StyledTableCell>
    </TableRow>
  );
};

export default TableItem;

TableItem.propTypes = {
  data: PropTypes.object.isRequired,
  customerId: PropTypes.number.isRequired,
};
