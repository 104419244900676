import {Grid, Typography} from '@mui/material';
import {blue, green, red} from '@mui/material/colors';
import StatsCardSecond from 'pages/dashboards/Metrics/StatsCardSecond';
import PropsType from 'prop-types';
import {useIntl} from 'react-intl';

const Matrix = ({title, allStatusCount, doneStatusCount, errorStatusCount}) => {
  const {messages} = useIntl();

  return (
    <Grid mt={20} item xs={12} container>
      <Grid item xs={12}>
        <Typography color={blue[500]} mb={4}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={4}>
        <StatsCardSecond
          text={messages['addData.errors']}
          value={errorStatusCount}
          bgColor={red[500]}
          icon={'/assets/images/metricsIcons/querries.svg'}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <StatsCardSecond
          text={messages['addData.info']}
          value={allStatusCount}
          bgColor={blue[500]}
          icon={'/assets/images/metricsIcons/info.svg'}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <StatsCardSecond
          text={messages['addData.confirm']}
          value={doneStatusCount}
          bgColor={green[500]}
          icon={'/assets/images/metricsIcons/order.svg'}
        />
      </Grid>
    </Grid>
  );
};

Matrix.propTypes = {
  title: PropsType.string,
  allStatusCount: PropsType.number.isRequired,
  doneStatusCount: PropsType.number.isRequired,
  errorStatusCount: PropsType.number.isRequired,
};

export default Matrix;
