import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import DataGL from './data/DataLG';
import AddDataGL from './addData/AddDataGL';
import DataGLW from './dataW/DataLG';

export const dataPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/data/all',
    element: <DataGL />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/data/upload-data',
    element: <AddDataGL />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/data/free-only',
    element: <DataGLW />,
  },
];
