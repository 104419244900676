import {Grid, TextField} from '@mui/material';
import PropTypes from 'prop-types';

const CustomForm = ({formik}) => {
  return (
    <Grid item xs={12}>
      <TextField
        label='curl request'
        fullWidth
        multiline
        maxRows={5}
        onChange={(e) => {
          formik.setFieldValue('additional_data_integration', {
            ...formik.values.additional_data_integration,
            curl: e.target.value,
          });
        }}
        value={formik.values.additional_data_integration.curl}
        required
      />
      <span>
        {
          'please make sure to replace the neeeded data in ${first_name} ${last_name} ${full_name} ${age} ${phone} ${id} ${email} ${country} ${country_iso_2} ${country_iso_3} ${country_name} &{country_phone_code} ${MPC_4} ${marks} ${other_data}  '
        }
      </span>
    </Grid>
  );
};
CustomForm.propTypes = {
  formik: PropTypes.object.isRequired,
};
export default CustomForm;
