import { useIntl } from 'react-intl';
import AccessableURLsUI from './accessableURLsUI';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { fetchError, showMessage } from 'redux/actions';

const accessableURLs = () => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearchQuery] = useState('');

  const onPageChange = (event, value) => {
    setPage(value);
  };

  const onSearchCustomer = (e) => {
    setSearchQuery(e);
    setPage(0);
  };

  const getCustomers = (pageSize, pageNumber, search) => {
    const url = search
      ? `forms/filter?pageSize=${pageSize}&pageNumber=${pageNumber}&term=${search}`
      : `forms/all?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    jwtAxios
      .get(url)
      .then((e) => {
        setCustomers(e.data.data.data);
        setCustomersCount(e.data.data.dataCount);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
  };
  const deleteForm = (id) => {
    jwtAxios
      .delete(`forms/${id}`)
      .then(() => {
        dispatch(showMessage('sccusfully data deleted id= ' + id));
        getCustomers(10, page, search);
      })
      .catch(() => {
        dispatch(fetchError("there is data form this form, you can not delete it"));
      });
  };

  useEffect(() => {
    if (search) {
      const getData = setTimeout(() => {
        getCustomers(10, page, search);
      }, 2000);
      return () => clearTimeout(getData);
    }
    getCustomers(10, page, search);
  }, [page, search]);

  return (
    <AccessableURLsUI
      page={page}
      onPageChange={onPageChange}
      customers={customers}
      customerCount={customersCount}
      messages={messages}
      onSearchCustomer={onSearchCustomer}
      deleteForm={deleteForm}
    />
  );
};

export default accessableURLs;
