import React, {useState} from 'react';
import {
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Modal,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import FormPagePreview from '../HtmlFormGen/component/formPagePreview';
import ModalButtom from '../HtmlFormGen/component/ModalButtom';

import Autocomplete from '@mui/lab/Autocomplete';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
// import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
// import FormPage from './component/formPage';
import countries from '../../../shared/countries/countries.json'; // Path to your JSON file
import {ChromePicker} from 'react-color'; // Import the ChromePicker component from react-color
import jwtAxios from '@crema/services/auth/jwt-auth';
import {fetchError} from 'redux/actions';
import {AppInfoView} from '@crema';

const DynamicFormGen = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [ageRanges, setAgeRanges] = useState([[1, 100]]);
  const [questions, setQuestions] = useState([]);
  const [fields, setFields] = useState([
    {name: 'First Name', required: true},
    {name: 'Last Name', required: true},
    {name: 'Phone Number', required: true},
  ]);
  const [showOptionalQuestions, setShowOptionalQuestions] = useState(false);
  const [ageType, setAgeType] = useState('text');
  const [ageRequired, setAgeRequired] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [formPreviewDataJson, setformPreviewDataJson] = useState(null); // Initialize to null or some default value
  const [htmlCode, setHtmlCode] = useState(''); // Replace this with actual HTML code from the backend
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [isGenerated, setIsGenerated] = useState(false);

  const defaultCountry = countries.find((country) => country.name === 'Qatar');
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [headerTitle, setHeaderTitle] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('Arabic'); // Default is Arabic
  const [buttonColor, setButtonColor] = useState('#7C0909'); // State to store the selected button color
  const [buttonTextColor, setButtonTextColor] = useState('#ffffff'); // State to store the selected button color
  const [labelsColor, setLabelsColor] = useState('#000000'); // State to store the selected button color

  const languages = ['Arabic', 'English'];
  const [buttonText, setButtonText] = useState(
    selectedLanguage === 'Arabic' ? 'إرسال' : 'submit',
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleCountryChange = (e) => {
    // get the selected country from your list
    const country = countries.find(
      (country) => country.name === e.target.value,
    );
    setSelectedCountry(country);
  };

  const handleButtonColorChange = (color) => {
    setButtonColor(color.hex);
  };
  const handleButtonTextColorChange = (color) => {
    setButtonTextColor(color.hex);
  };

  const handleLabelsColorColorChange = (color) => {
    setLabelsColor(color.hex);
  };
  const handleFieldRequiredChange = (e, index) => {
    const newFields = [...fields];
    newFields[index].required = e.target.checked;
    setFields(newFields);
  };

  const handleAddAgeRange = () => {
    setAgeRanges([...ageRanges, [1, 100]]);
  };

  const handleAgeRangeChange = (e, rangeIndex, boundaryIndex) => {
    const newAgeRanges = [...ageRanges];

    newAgeRanges[rangeIndex][boundaryIndex] = e.target.value;
    setAgeRanges(newAgeRanges);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {question: '', type: 'text', options: [], required: false},
    ]);
  };
  const handleButtonText = (e) => {
    setButtonText(e);
  };
  const handleHeaderTitle = (e) => {
    setHeaderTitle(e);
  };
  const handleSetEmailReq = (e) => {
    if (showEmail) {
      setEmailRequired(e);
    } else {
      dispatch(fetchError('Please enable email option first'));
    }
  };
  const handelSetAgeRequired = (e) => {
    if (showAge) {
      setAgeRequired(e);
    } else {
      dispatch(fetchError('Please Enable Age Option First'));
    }
  };
  const handelSetEmailShow = (e) => {
    if (!e) {
      setShowEmail(e);
      setEmailRequired(e);
    } else {
      setShowEmail(e);
    }
  };
  const handelSetShowAge = (e) => {
    if (!e) {
      setShowAge(e);
      setAgeRequired(e);
    } else {
      setShowAge(e);
    }
  };
  const handleLanguageChange = (e) => {
    setSelectedLanguage(e);
    setButtonText(e === 'Arabic' ? 'إرسال' : 'submit');
  };
  const handleQuestionChange = (e, index, field) => {
    const newQuestions = [...questions];
    if (field === 'options') {
      newQuestions[index][field] = e.target.value;
    } else if (field === 'required') {
      newQuestions[index][field] = e.target.checked; // Use checked property for checkbox
    } else {
      newQuestions[index][field] = e.target.value;
      if (field === 'type' && e.target.value === 'text') {
        newQuestions[index].options = [];
      }
    }
    setQuestions(newQuestions);
  };
  const handleSubmitPreview = (e) => {
    e.preventDefault();

    // Create a JSON object to store the form data
    const formPreviewDataJson = {
      id: 99999999,
      MPC_4: formData.mpc4 || '',
      marks: formData.marks || '',
      country: selectedCountry,
      form_info: {
        country: selectedCountry,
        language: selectedLanguage,
        showAge: showAge,
        showEmail: showEmail,
        formName: formData.formName,
        mpc4: formData.mpc4,
        emailRequired: emailRequired,
        marks: formData.marks,
        headerTitle: headerTitle ? headerTitle : '',
        fields: fields.map((field) => ({
          name: field.name,
          required: field.required,
        })),
        ageRequired: ageRequired,
        buttonColor: buttonColor,
        labelsColor: labelsColor,
        buttonText: buttonText,
        buttonTextColor: buttonTextColor,
        ageType: ageType,
        ageRanges: ageType === 'range' ? ageRanges : null,
        questions: questions.map((question) => ({
          question: question.question,
          type: question.type,
          options: question.type === 'dropdown' ? question.options : null,
          required: question.required,
        })),
      },
    };
    setformPreviewDataJson(formPreviewDataJson);
    setIsGenerated(false);
    handleOpen(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a JSON object to store the form data
    const formDataJson = {
      country: selectedCountry,
      language: selectedLanguage,
      showAge: showAge,
      showEmail: showEmail,
      formName: formData.formName,
      mpc4: formData.mpc4,
      emailRequired: emailRequired,
      marks: formData.marks,
      headerTitle: headerTitle ? headerTitle : '',
      fields: fields.map((field) => ({
        name: field.name,
        required: field.required,
      })),
      ageRequired: ageRequired,
      buttonColor: buttonColor,
      labelsColor: labelsColor,
      buttonText: buttonText,
      buttonTextColor: buttonTextColor,
      ageType: ageType,
      ageRanges: ageType === 'range' ? ageRanges : null,
      questions: questions.map((question) => ({
        question: question.question,
        type: question.type,
        options: question.type === 'dropdown' ? question.options : null,
        required: question.required,
      })),
    };
    setformPreviewDataJson(formDataJson);
    setIsSubmitted(true);
    jwtAxios
      .post('forms/', {
        MPC_4: formDataJson.mpc4,
        form_info: formDataJson,
        country: formDataJson.country.iso,
        marks: formDataJson.marks,
      })
      .then((e) => {
        setHtmlCode(
          `<iframe src="https://www.blackdawn.info/forms/${e.data?.data?.id}" width="640" height="956" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`,
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      })
      .finally(() => {
        setIsSubmitted(true);
        setIsGenerated(true);
      });
    // Perform any additional logic or API calls with formDataJson as needed
  };

  return (
    <>
      <AppInfoView />
      <form onSubmit={handleSubmit}>
        <Box marginBottom={2}>
          <Card>
            <CardHeader subheader='Form Configuration' />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id='form-name'
                    label='Form Name'
                    required
                    fullWidth
                    onChange={(e) => handleChange(e, 'formName')}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id='mpc4'
                    label='MPC4'
                    required
                    fullWidth
                    onChange={(e) => handleChange(e, 'mpc4')}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id='marks'
                    label='Marks'
                    required
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => handleChange(e, 'marks')}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={selectedCountry ? selectedCountry.name : ''}
                    fullWidth
                    onChange={handleCountryChange}
                  >
                    {countries.map((country, index) => (
                      <MenuItem value={country.name} key={index}>
                        <img
                          loading='lazy'
                          width='20'
                          src={`https://flagcdn.com/w20/${country.iso.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${country.iso.toLowerCase()}.png 2x`}
                          alt=''
                        />
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel>Language</InputLabel>
                  <Select
                    value={selectedLanguage}
                    fullWidth
                    onChange={(e) => handleLanguageChange(e.target.value)}
                  >
                    {languages.map((language, index) => (
                      <MenuItem value={language} key={index}>
                        {language}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box marginBottom={2}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  {/* Color Picker for Button */}
                  <InputLabel>Button And Border Color</InputLabel>
                  <ChromePicker
                    color={buttonColor}
                    onChange={handleButtonColorChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  {/* Color Picker for Button Text */}
                  <InputLabel>Button Text Color</InputLabel>
                  <ChromePicker
                    color={buttonTextColor}
                    onChange={handleButtonTextColorChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  {/* Color Picker for Button */}
                  <InputLabel>Form Label Color</InputLabel>
                  <ChromePicker
                    color={labelsColor}
                    onChange={handleLabelsColorColorChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  {/* Text box for button text */}
                  <InputLabel style={{marginTop: '16px'}}>
                    Button Text
                  </InputLabel>
                  <TextField
                    variant='outlined'
                    fullWidth
                    value={buttonText}
                    placeholder='button Text'
                    onChange={(e) => handleButtonText(e.target.value)}
                    // Adjust the value as needed
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* Text box for button text */}
                  <InputLabel style={{marginTop: '16px'}}>
                    Form Title
                  </InputLabel>
                  <TextField
                    variant='outlined'
                    fullWidth
                    placeholder='Form Title'
                    onChange={(e) => handleHeaderTitle(e.target.value)}
                    // Adjust the value as needed
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box marginBottom={2}>
          <Card mb={4}>
            <CardHeader subheader='Questions Configuration' />
            <CardContent>
              <Grid container spacing={4}>
                {fields.map((field, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.required}
                          onChange={(e) => handleFieldRequiredChange(e, index)}
                          name='required'
                        />
                      }
                      label={field.name + ' Required'}
                    />
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={emailRequired}
                        onChange={(e) => handleSetEmailReq(e.target.checked)}
                        name='Email'
                      />
                    }
                    label='Email Required'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showEmail}
                        onChange={(e) => handelSetEmailShow(e.target.checked)}
                        name='Show Email'
                      />
                    }
                    label='Show Email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ageRequired}
                        onChange={(e) => handelSetAgeRequired(e.target.checked)}
                        name='age Required'
                      />
                    }
                    label='Age Required'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showAge}
                        onChange={(e) => handelSetShowAge(e.target.checked)}
                        name='Show Age'
                      />
                    }
                    label='Show Age'
                  />
                </Grid>
                {showAge ? (
                  <Grid item xs={12}>
                    <InputLabel>Age Type</InputLabel>
                    <Select
                      value={ageType}
                      onChange={(e) => setAgeType(e.target.value)}
                    >
                      <MenuItem value={'text'}>Text</MenuItem>
                      <MenuItem value={'range'}>Range</MenuItem>
                    </Select>
                  </Grid>
                ) : (
                  ''
                )}
                {ageType === 'range' &&
                  showAge &&
                  ageRanges.map((range, rangeIndex) => (
                    <Grid item xs={12} sm={4} key={rangeIndex}>
                      <Typography variant='body1' sx={{mb: 2}}>
                        •
                      </Typography>{' '}
                      {/* Add the label here */}
                      <TextField
                        label={'Age Range Start'}
                        type='number'
                        value={range[0]}
                        onChange={(e) => handleAgeRangeChange(e, rangeIndex, 0)}
                        sx={{mr: 2, mb: 2}} // Add right margin
                      />
                      <TextField
                        label={'Age Range End'}
                        type='number'
                        value={range[1]}
                        onChange={(e) => handleAgeRangeChange(e, rangeIndex, 1)}
                      />
                    </Grid>
                  ))}
                {ageType === 'range' && showAge && (
                  <Grid item xs={12}>
                    <Button onClick={handleAddAgeRange}>
                      Add Another Age Range
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showOptionalQuestions}
                      onChange={(e) =>
                        setShowOptionalQuestions(e.target.checked)
                      }
                    />
                  }
                  label='Optional Questions Configuration'
                />
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {showOptionalQuestions && (
          <Box marginBottom={2}>
            <Card mb={4}>
              <CardHeader subheader='Optional Questions Configuration' />
              <CardContent>
                {questions.map((question, index) => (
                  <Grid item xs={12} sm={12} sx={{mb: 2}} key={index}>
                    <Typography variant='body1' sx={{mb: 2}}>
                      •
                    </Typography>{' '}
                    <TextField
                      fullWidth // Add this prop to make the TextField full width
                      xs={12}
                      label='Question'
                      value={question.question}
                      onChange={(e) =>
                        handleQuestionChange(e, index, 'question')
                      }
                    />
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={question.type}
                      onChange={(e) => handleQuestionChange(e, index, 'type')}
                    >
                      <MenuItem value={'text'}>Text</MenuItem>
                      <MenuItem value={'dropdown'}>Dropdown</MenuItem>
                    </Select>
                    {question.type === 'dropdown' && (
                      <Autocomplete
                        multiple
                        id='tags-outlined'
                        options={[]}
                        freeSolo
                        onChange={(event, newValue) =>
                          handleQuestionChange(
                            {target: {value: newValue}},
                            index,
                            'options',
                          )
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant='outlined'
                              label={option}
                              {...getTagProps({index})}
                              key={index}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Options'
                            placeholder='Options'
                          />
                        )}
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={question.required}
                          onChange={(e) =>
                            handleQuestionChange(e, index, 'required')
                          }
                        />
                      }
                      label='Required'
                    />
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Button onClick={handleAddQuestion}>
                    Add Another Question
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <Box marginBottom={2}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={3} md={2}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={isSubmitted}
                    fullWidth
                  >
                    Generate
                  </Button>
                </Grid>
                <Grid item xs={4} sm={3} md={1}>
                  <Button
                    onClick={handleSubmitPreview}
                    variant='contained'
                    color='secondary'
                  >
                    Preview
                  </Button>
                </Grid>
                {/* New Grid item for Typography */}
                <Grid item xs={12}>
                  {isGenerated ? (
                    <Typography
                      variant='h6' // Adjust the variant as needed
                      style={{
                        fontWeight: 'bold',
                        color: 'green',
                        fontSize: '14px', // Set to your desired green
                      }}
                    >
                      The form has been created successfully. To create another
                      form, you can modify the appearance, complete the changes,
                      and preview the form before creating it
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </form>

      <Box marginBottom={2}>
        <Card>
          <CardHeader title='Iframe Tag For Your Form' />
          <CardContent>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={8}
                value={htmlCode}
                variant='outlined'
                placeholder='Please Generate a Form'
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='parent-modal-title'
          aria-describedby='parent-modal-description'
        >
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional backdrop color
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Grid
              item
              xs={12}
              sm={9}
              md={6} // You can set these to any breakpoints you want
            >
              <Box
                style={{
                  backgroundColor: 'white',
                  outline: 'none',
                  padding: '1rem',
                  maxHeight: '80vh', // or whatever size you want to set as max height
                  overflow: 'auto', // makes content scrollable if it's too large
                }}
              >
                <CardContent>
                  <Typography mb={2}>
                    When you use Iframe tag the Form Will Appear Like This
                  </Typography>{' '}
                  {/* This is your title */}
                  <FormPagePreview disabled data={formPreviewDataJson} />
                  <ModalButtom
                    handleClose={handleClose}
                    setIsSubmitted={setIsSubmitted}
                    setHtmlCode={setHtmlCode}
                  />
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

DynamicFormGen.propTypes = {
  description: PropTypes.node,
};

export default DynamicFormGen;
