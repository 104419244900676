import {Grid, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';

const CustomForm = ({setCurlCode}) => {
  return (
    <Grid item xs={12}>
      <Typography mb={4}>User Input CURL</Typography>

      <TextField
        label='curl(bash) request '
        fullWidth
        multiline
        maxRows={5}
        onChange={setCurlCode}
        required
      />
      <span>
        {
          'please make sure to replace the neeeded data in ${first_name} ${last_name} ${full_name} ${age} ${phone} ${id} ${email} ${country} ${country_iso_2} ${country_iso_3} ${country_name} &{country_phone_code} ${MPC_4} ${marks} ${other_data}  '
        }
      </span>
    </Grid>
  );
};
CustomForm.propTypes = {
  setCurlCode: PropTypes.func.isRequired,
};
export default CustomForm;
