import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import defaultConfig, {
  backgroundDark,
  backgroundLight,
  backgroundWhite,
  defaultTheme,
  textDark,
  textLight,
} from './defaultConfig';
import PropTypes from 'prop-types';
import {LayoutDirection, ThemeMode} from '../../../shared/constants/AppEnums';

const ThemeContext = createContext();
const ThemeActionsContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const useThemeActionsContext = () => useContext(ThemeActionsContext);

const ThemeContextProvider = ({children}) => {
  const [theme, setTheme] = useState(defaultTheme.theme);
  const [themeMode, updateThemeMode] = useState(defaultConfig.themeMode);
  const [themeStyle, updateThemeStyle] = useState(defaultConfig.themeStyle);

  const updateTheme = useCallback((theme) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    setTheme(theme);
  }, []);

  useEffect(() => {
    // Function to calculate the new palette
    const getUpdatedPalette = () => {
      const isWhiteBackgroundURL = /\/forms\/\d+/.test(
        window.location.pathname,
      );
      return {
        ...theme.palette,
        mode: themeMode === ThemeMode.DARK ? ThemeMode.DARK : ThemeMode.LIGHT,
        background: isWhiteBackgroundURL
          ? backgroundWhite
          : themeMode === ThemeMode.DARK
          ? backgroundDark
          : backgroundLight,
        text: themeMode === ThemeMode.DARK ? textDark : textLight,
      };
    };

    // Calculate the new palette
    const updatedPalette = getUpdatedPalette();

    // Check if the background has actually changed
    if (theme.palette.background !== updatedPalette.background) {
      // Update the theme if the background has changed
      updateTheme({
        ...theme,
        palette: updatedPalette,
      });
    }
  }, [themeMode, theme.palette.background, updateTheme]); // Only include relevant dependencies

  useEffect(() => {
    if (theme.direction === LayoutDirection.RTL) {
      document.body.setAttribute('dir', LayoutDirection.RTL);
    } else {
      document.body.setAttribute('dir', LayoutDirection.LTR);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeStyle,
        themeMode,
      }}
    >
      <ThemeActionsContext.Provider
        value={{
          updateTheme,
          updateThemeStyle,
          updateThemeMode,
        }}
      >
        {children}
      </ThemeActionsContext.Provider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
