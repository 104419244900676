import React from 'react';
import EmployeesTable from './EmployeesTable';
import AppsContainer from '../../../@crema/core/AppsContainer';
import {Button, Hidden} from '@mui/material';
import AppsHeader from '../../../@crema/core/AppsContainer/AppsHeader';
import AppsContent from '../../../@crema/core/AppsContainer/AppsContent';
import AppsPagination from '../../../@crema/core/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '../../../@crema/core/AppInfoView';
import PropTypes from 'prop-types';

const EmployeesUI = ({
  onPageChange,
  OpenAddEmployee,
  messages,
  employeeCount,
  page,
  employees,
  handleDeactivate,
}) => {
  return (
    <>
      <AppsContainer title={messages['sidebar.ecommerce.customers']} fullView>
        <AppsHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={OpenAddEmployee}
              >
                Add employee
              </Button>

              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={10}
                  count={employeeCount}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <EmployeesTable
            employees={employees}
            handleDeactivate={handleDeactivate}
          />
        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={10}
            count={employeeCount}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>

      <AppInfoView />
    </>
  );
};
EmployeesUI.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  OpenAddEmployee: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  employeeCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  employees: PropTypes.array.isRequired,
  handleDeactivate: PropTypes.func.isRequired,
};
export default EmployeesUI;
