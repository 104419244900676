import {fetchEventSource} from '@microsoft/fetch-event-source';
import axios from 'axios';

const jwtAxios = axios.create({
  baseURL: 'https://be.blackdawn.info', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export const sseRequest = async (path, options) => {
  let headers = options.contentType
    ? {
        Authorization: jwtAxios.defaults.headers.common['Authorization'],
        Accept: 'text/event-stream',
        'content-type': options.contentType,
      }
    : {
        Authorization: jwtAxios.defaults.headers.common['Authorization'],
        Accept: 'text/event-stream',
      };

  await fetchEventSource(`https://be.blackdawn.info/${path}`, {
    method: 'POST',
    headers: headers,
    body: options.body,
    onmessage: options.onmessage,
    onopen: options.onopen,
    keepalive: true,
    openWhenHidden: true,
    window,
    signal: AbortSignal.timeout(10000000),
    onclose: () => {
      options.onclose();
    },
    onerror: (err) => {
      options.onerror(err);
      throw err; // rethrow to stop the operation
    },
  });
};

export default jwtAxios;
