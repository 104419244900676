import {useFormik} from 'formik';
import AddEmployeeUI from './AddEmployeeUI';
import {useState} from 'react';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {AppInfoView} from '@crema';
import {useDispatch} from 'react-redux';
import {fetchError} from 'redux/actions';
import PasswordEncrypter from '@crema/utility/PasswordEncrypter';

const AddEmployee = () => {

  // Custom validation function
  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    }
    if (!values.role) {
      errors.role = 'Role is required';
    }
    return errors;
  };

  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const formik = useFormik({
    onSubmit: (values) => {
      setIsAdding(true);
      jwtAxios
        .post(`user-jwt`, {...values,password:PasswordEncrypter(values.password), active: isActive})
        .then(() => {
          window.location.reload();
        })
        .catch((e) => dispatch(fetchError(e.messages)))
        .finally(() => setIsAdding(true));
    },
    initialValues: {
      role: '',
      name: '',
      email: '',
      password: '',
    },
    validate
  });

  return (
    <>
      <AppInfoView />
      <AddEmployeeUI
        formik={formik}
        isAdding={isAdding}
        isActive={isActive}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default AddEmployee;
