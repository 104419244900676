import React from 'react';
import { TextField, Box, Typography, FormControlLabel, Checkbox, Autocomplete, Button, Card, CardHeader, CardContent } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { AppAnimate } from '@crema';
import { Fonts } from 'shared/constants/AppEnums';
import {authRole} from 'shared/constants/AppConst';

const AddEmployeeUI = ({ formik, isAdding, isActive, setIsActive }) => {
  return (
    <form onSubmit={formik.handleSubmit}>
       <AppAnimate animation='transition.slideUpIn' delay={200}>
       <Card sx={{ minHeight: '600px' }}> {/* Set a minimum height for the Card */}
          <CardHeader
            sx={{
              py: 4,
              pb: 1,
              px: 5,
              display: 'flex',
              alignItems: 'center',
              minHeight: 50,
              boxSizing: 'border-box',
              '& .MuiTypography-h5': {
                fontSize: 14,
                fontWeight: Fonts.BOLD,
                marginBottom: 0.25,
              },
            }}
            // title={title}
            root={{
              subheader: {
                fontSize: 13,
              },
            }}
          />

          <CardContent sx={{px: 10, pt: 1}}>
      <Box sx={{ margin: 'auto', maxWidth: 500 }}>
        <Typography variant="h6" sx={{ mb: 3 }}>Add Employee</Typography>

        {/* Name Field */}
        <TextField
          fullWidth
          margin="normal"
          id='name'
          name='name'
          label='Name'
          variant='outlined'
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Email Field */}
        <TextField
          fullWidth
          margin="normal"
          id='email'
          name='email'
          label='Email'
          variant='outlined'
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Password Field */}
        <TextField
          fullWidth
          margin="normal"
          id='password'
          name='password'
          label='Password'
          type='password'
          variant='outlined'
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKeyIcon />
              </InputAdornment>
            ),
          }}
        />

       

        {/* Role Field */}
        <Autocomplete
          disablePortal
          id='role'
          options={Object.keys(authRole)}

          sx={{ width: '100%', mt: 2 }}
          onChange={(_, value) => formik.setFieldValue('role', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Role'
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessCenterIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        {/* Activate Checkbox */}
        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={() => setIsActive(!isActive)} />}
          label='Activate'
          sx={{ mt: 2 }}
        />

        {/* Submit Button */}
        <Box mt={5}>
          {isAdding ? (
            <LoadingButton loading variant='outlined' fullWidth>
              Adding...
            </LoadingButton>
          ) : (
            <Button type='submit' variant='contained' color='primary' fullWidth>
              Add Employee
            </Button>
          )}
        </Box>
      </Box>
      </CardContent>
        
        </Card>
      </AppAnimate>
    </form>
  );
};

export default AddEmployeeUI;

AddEmployeeUI.propTypes = {
  description: PropTypes.node,
  formik: PropTypes.any.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
};


