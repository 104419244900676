import {Box, Button, Modal} from '@mui/material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ConfirmModal = ({open, handleClose}) => {
  return (
    <>
      <Modal
        open={open}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{...style, width: 400}}>
          <h2 id='parent-modal-title'>customer updated succesfully</h2>

          <Button
            variant='contained'
            onClick={() => {
              handleClose();
              window.location.reload();
            }}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};
ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ConfirmModal;
