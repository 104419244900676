// import {FaRegCalendarAlt} from 'react-icons/fa';
// import {FiMap, FiUsers} from 'react-icons/fi';
// import {RiShieldUserLine} from 'react-icons/ri';
// import {BiCarousel, BiCartAlt, BiErrorAlt} from 'react-icons/bi';
import {BsFileEarmarkPersonFill, BsFileEarmarkPlusFill} from 'react-icons/bs';
// import {DiHtml5Multimedia} from 'react-icons/di';
// import {MdOutlineContactSupport, MdOutlineManageAccounts} from 'react-icons/md';

// import {ImFeed, ImLab} from 'react-icons/im';
// import {GrDatabase, GrNavigate} from 'react-icons/gr';
// import {VscTable, VscTools} from 'react-icons/vsc';
// import {AiOutlineLayout, AiOutlineUnorderedList} from 'react-icons/ai';
import {UploadFileOutlined} from '@mui/icons-material';

const routesConfig = [
  {
    id: 'client',
    title: 'Clients',
    messageId: 'sidebar.clients',
    type: 'collapse',
    children: [
      {
        id: 'clients',
        title: 'Clients',
        messageId: 'sidebar.app.dashboard.clients',
        type: 'item',
        icon: <BsFileEarmarkPersonFill />,
        url: '/clients/all',
      },
      {
        id: 'addClients',
        title: 'Add Clients',
        messageId: 'sidebar.app.dashboard.addClients',
        type: 'item',
        icon: <BsFileEarmarkPlusFill />,
        url: '/clients/add-client',
      },
      {
        id: 'uploadDataToClients',
        title: 'upload Data To Clients',
        messageId: 'sidebar.app.dashboard.uploadToClient',
        type: 'item',
        icon: <UploadFileOutlined />,
        url: '/clients/upload-data-to-client',
      },
    ],
  },
  {
    id: 'data',
    title: 'Data',
    messageId: 'sidebar.data',
    type: 'collapse',
    children: [
      {
        id: 'data',
        title: 'Data',
        messageId: 'sidebar.app.dashboard.data',
        type: 'item',
        icon: <BsFileEarmarkPersonFill />,
        url: '/data/free-only',
      },
      {
        id: 'dataFreeOnly',
        title: 'Data Free Only',
        messageId: 'sidebar.app.dashboard.dataFreeOnly',
        type: 'item',
        icon: <BsFileEarmarkPersonFill />,
        url: '/data/all',
      },
      {
        id: 'addClients',
        title: 'Add Clients',
        messageId: 'sidebar.app.dashboard.addData',
        type: 'item',
        icon: <BsFileEarmarkPlusFill />,
        url: '/data/upload-data',
      },
    ],
  },
  {
    id: 'employees',
    title: 'Employees',
    messageId: 'sidebar.ecommerce.employee',
    type: 'collapse',
    children: [
      {
        id: 'employees',
        title: 'Employees',
        messageId: 'sidebar.ecommerce.employee',
        type: 'item',
        icon: <BsFileEarmarkPersonFill />,
        url: '/employees/all',
      },
      {
        id: 'addEmployees',
        title: 'Add Employees',
        messageId: 'sidebar.ecommerce.addemployee',
        type: 'item',
        icon: <BsFileEarmarkPlusFill />,
        url: '/employees/add-employee',
      },
    ],
  },
  {
    id: 'forms',
    title: 'Forms',
    messageId: 'sidebar.ecommerce.forms',
    type: 'collapse',
    children: [
      {
        id: 'forms',
        title: 'Forms',
        messageId: 'sidebar.ecommerce.accessableforms',
        type: 'item',
        icon: <BsFileEarmarkPersonFill />,
        url: '/forms/Allfroms',
      },

      {
        id: 'htmlFormGen',
        title: 'HtmlFormGen',
        messageId: 'sidebar.ecommerce.htmlFormGen',
        type: 'item',
        icon: <BsFileEarmarkPersonFill />,
        url: '/forms/htmlFormGen',
      },
    ],
  },
];
export default routesConfig;
